<template>
  <div class="editor">
    <LoginScreenComponent @loggedInCallback="loggedInCallback" @dayMessagesCallback="dayMessagesCallback"/>
  </div>
</template>

<script>
import LoginScreenComponent from '@/components/LoginScreenComponent.vue';

export default {
  name: 'LoginView',
  components: {
    LoginScreenComponent,
  },
  data() {
    return {
      dayMessages: null,
    };
  },
  props: [],
  methods: {
    loggedInCallback(u, t) {
      this.$emit('loggedInCallback', u, t);
    },
    dayMessagesCallback(m) {
      this.$emit('dayMessagesCallback', m);
    },
  },
};
</script>
