<template>
  <vue-final-modal :focus-retain="false" v-slot="{ params }" classes="modal-container" content-class="modal_window">
    <div class="modal_title">
      <slot name="title"></slot>
    </div>
    <div class="modal_content">
      <slot :params="params"></slot>
    </div>
    <div class="modal_action" v-if="displayCancel !=='' || displayConfirm !== ''"
      :class="{'single_button': (displayCancel === '' && !displayConfirm !== '') || (displayCancel !== '' && !displayConfirm === '') }">
      <button v-if="displayCancel !== ''" class="cancel" @click="$emit('cancel')">{{ displayCancel }}</button>
      <button v-if="displayConfirm !== ''" class="confirmation" @click="$emit('confirm')">{{ displayConfirm }}</button>
    </div>
    <button class="modal_close" @click="$emit('cancel')">
      <i class='fas fa-times-circle'/>
    </button>
  </vue-final-modal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-nested-ternary */

export default {
  name: 'CustomModal',
  props: ['showCancel', 'showConfirm'],
  emits: ['confirm', 'cancel'],
  computed: {
    displayCancel() {
      return this.showCancel === false ? '' : (this.showCancel !== null && this.showCancel !== undefined) ? this.showCancel : this.$t('general.cancel');
    },
    displayConfirm() {
      return this.showConfirm === false ? '' : (this.showConfirm !== null && this.showConfirm !== undefined) ? this.showConfirm : this.$t('general.ok');
    },
  },
};
</script>

<style scoped>

::v-deep(.modal_window) {
  position: absolute;
  background: white;
  width: 640px;
  margin: 20px;
  left: calc(50% - 20px);
  top: 70px;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  transform: translateX(-50%);
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  transition: all ease-in-out 0.8s;
  max-height: 70%;
  display: flex;
  flex-direction: column;
}

::v-deep(.modal_content) {
  padding: 16px 0px 32px 0px;
  flex: 1 1 auto;
  overflow-y: auto;
}

::v-deep(.modal_title) {
  line-height: 40px;
  font-size: 28px;
  color: v-bind(primaryColor);
  background-color: white;
  margin: 0;
  width: calc(100% - 40px);
  flex: 0 1 auto;
}

::v-deep(.modal_close) {
  background: transparent;
  color: var(--color_light_gray);
  position: absolute;
  border: none;
  top: 20px;
  right: 20px;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

::v-deep(.modal_action) {
  line-height: 40px;
  font-size: 28px;
  margin: 0;
  margin-top: 8px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 0px 0px;
  flex: 0 1 auto;
}

::v-deep(.modal_action.single_button) {
  justify-content: center;
}

::v-deep(.modal_action button) {
  border: none;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  width: 46%;
  line-height: 40px;
  color: white;
  border-radius: 8px;
}

::v-deep(.modal_action button.confirmation) {
  background-color: var(--color_orange);
}

::v-deep(.modal_action button.cancel) {
  background-color: var(--color_mint);
}

::v-deep(.modal_action button:hover) {
  font-weight: 800;
}

::v-deep(.vfm--overlay) {
  background-color: rgba(52,52,52,0.5);
}

::v-deep(.modal_content .form) {
  text-align: left;
}

::v-deep(.modal_content .form .label) {
  width: 28%;
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

::v-deep(.modal_content .form .value) {
  width: 68%;
  display: inline-block;
}

::v-deep(.modal_content .form .row) {
  padding: 8px;
}

::v-deep(.modal_content .form .row input) {
  width: calc(100% - 15px);
  padding: 4px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

::v-deep(.modal_content .form .row input[type='checkbox']) {
  width: auto;
}

::v-deep(.modal_content .form .row .hint) {
  font-size: 0.75em;
}

::v-deep(.modal_content .form hr) {
  height: 1px;
  background-color: var(--color_orange);
}

::v-deep(.modal_content table td), ::v-deep(.modal_content table th) {
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding: 0px;
  white-space: nowrap;
}

::v-deep(.modal_content .form .row .label) {
  vertical-align: top;
}

::v-deep(.modal_content .form .row .fieldError) {
  color: var(--color_red);
  font-size: 12px;
}

::v-deep(.modal_content .form .row .showPassword) {
  cursor: pointer;
  color: gray;
  position: absolute;
  right: 0px;
  top: 0px;
}

::v-deep(.modal_content .form .row) {
  position: relative;
}

::v-deep(.modal_content .form .row input.inline) {
  display: inline-block;
  width: auto;
}

@media(max-width: 640px) {
  ::v-deep(.modal_window) {
    width: calc(100% - 20px);
    padding: 8px;
  }
  ::v-deep(.modal_content) {
    padding: 0px;
  }

  ::v-deep(.modal_content .form .value) {
    width: 68%;
  }

  ::v-deep(.modal_content table) {
    width: 100%;
  }

  ::v-deep(.modal_content table td), ::v-deep(.modal_content table th) {
    font-size: 10px;
  }

  ::v-deep(.modal_content table td.removeColumn), ::v-deep(.modal_content table th.removeColumn) {
    max-width: 20px;
  }

  ::v-deep(.modal_close) {
    top: 10px;
    right: 10px;
  }

  ::v-deep(.modal_title) {
    font-size: 22px;
  }

  ::v-deep(.modal_content .form .row .showPassword) {
    right: 0px;
  }

}
</style>
