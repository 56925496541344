<template>
  <div id="hintApp">
    <div class="hintcontainer">
      <h1>Colada</h1>
      <img class="colada-icon" src="./assets/colada_icon.png" />
      <h2 v-html="hint"></h2>
      <br/>
      <button @click="backToPortal">Ok</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: { },
  data() {
    return {
    };
  },
  computed: {
    hint() {
      return document.location.href.indexOf('emailConfirmationSuccessful') > 0
        ? 'Your E-Mail-Address has been successfully confirmed!'
        : '<span class="error">An error occured!</span><br/>Your E-Mail-Address could not be or has already been confirmed!';
    },
  },
  methods: {
    backToPortal() {
      document.location.href = '/';
    },
  },
};
</script>

<style>
@import url('./styles/app.css');
#hintApp {
  width: 100%;
  height: 100%;
  background-color: var(--color_mint);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#hintApp .hintcontainer {
  max-width: 500px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 8px var(--color_dark_green);
  padding: 32px;
  position: absolute;
  margin: 16px;
}
#hintApp .colada-icon {
  width: 120px;
  margin-bottom: 16px;
}
#hintApp span.error {
  color: var(--color_orange);
}

#hintApp button {
  width: 100px;
  background-color: var(--color_orange);
  padding: 8px;
  border: none;
  color: white;
  font-size: 1.4em;
  border-radius: 12px;
}

#hintApp h2 {
  font-size: 1.4em;
}

@media(max-width: 500px) {
  #hintApp h1 {
    font-size: 24px;
  }

  #hintApp h2 {
    font-size: 1.2em;
  }

  #hintApp button {
    font-size: 1.2em;
  }
}
</style>
