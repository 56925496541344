<template>
  <div class="loginBG">
    <div v-if="!loading">
      <img class="colada-logo" src="../assets/colada_logo.png" />
      <img class="login-form-background-image" src="../assets/login_background.png" />
      <div class="intro-wrapper" v-if="showIntro">
        <h1 class="intro-title" v-html="$t('login.introTitle')"></h1>
        <div class="spacer"></div>
        <div class="intro-text" v-html="$t('login.introText')"></div>
        <div class="actionButton mint" @click="createFreeTenant">{{$t('login.startForFree')}}</div>
        <div class="spacer"></div>
        <div class="intro-text" v-html="$t('login.introText2')"></div>
        <div class="actionButton white" @click="showLoginDialog">{{$t('login.showLogin')}}</div>
      </div>

      <div class="login-form-wrapper" v-if="!showIntro">
        <h2 class="login-form-title">{{ $t('pageTitle.login') }}<span v-if="tenantName != null"><br/>{{tenantName}}</span></h2>
        <input class="login-form-input" :placeholder="$t('placeholder.username')" type="text" v-model="email"/>
        <div style="position: relative">
          <span v-if="showPW" @click="showPW = !showPW"><i class="btnShowPassword fas fa-eye"/></span>
          <span v-if="!showPW" @click="showPW = !showPW"><i class="btnShowPassword fas fa-eye-slash"/></span>
          <input class="login-form-input txtPassword" :placeholder="$t('placeholder.password')" :type="showPW ? 'text' : 'password'" v-model="password" @keyup.enter = "performLogin"/>
        </div>
        <input :disabled="ciLocked" class="login-form-input connectionInfo"
          :placeholder="$t('placeholder.connectionInfo')" type="text"
          v-model="connectionInfo" @keyup.enter = "performLogin" @blur="parseConnInfo"/><br/>
        <div v-if ="ciLocked" class="lock" @click="ciLocked = !ciLocked"><i class="fas fa-lock"/></div>
        <div v-if ="!ciLocked" class="lock" @click="ciLocked = !ciLocked"><i class="fas fa-lock-open"/></div>
        <br/>
        <div class="button-wrapper"><button class="login-form-button" @click="performLogin()">{{$t('login.btnLogIn')}}</button></div>
        <div class="free-tenant-link float-left" @click="showOnboarding">{{$t('login.onboarding')}}</div>
        <div class="free-tenant-link" @click="createFreeTenant">{{$t('login.createFree')}}</div>
        <div class="legal-link" @click="showLegalModal = true">{{$t('login.terms')}}</div>
        <div class="legal-link" @click="showPrivacyModal = true">{{$t('login.privacy')}}</div>
        <div class="legal-link" @click="showImprintModal = true">{{$t('login.imprint')}}</div>
      </div>
    </div>
    <div v-else>
      {{ $t('message.loading') }}
    </div>
    <div class="error" v-if="errorMessage != null">{{errorMessage}}</div>
    <CreateFreeTenantModal ref="newTenantModal"/>
    <PromptModal ref="adminPWModal" :title="$t('modal.titleAdminPassword')" :text="$t('modal.textAdminPassword')"/>
    <PromptModal ref="magicLinkPW" :title="$t('modal.titleMagicLink')" :text="$t('modal.textMagicLink')"/>
    <OnboardingModal ref="onboardingModal"/>
    <CustomModal class="legalModal" v-model="showLegalModal" :showCancel="false" @confirm="showLegalModal = false" @cancel="showLegalModal = false">
      <template #title>{{$t('login.terms')}}</template>
      <TermsOfUsageComponent/>
    </CustomModal>
    <CustomModal class="legalModal" v-model="showPrivacyModal" :showCancel="false" @confirm="showPrivacyModal = false" @cancel="showPrivacyModal = false">
      <template #title>{{$t('login.privacy')}}</template>
      <PrivacyComponent/>
    </CustomModal>
    <CustomModal class="imprintModal" v-model="showImprintModal" :showCancel="false" @confirm="showImprintModal = false" @cancel="showImprintModal = false">
      <template #title>{{$t('login.imprint')}}</template>
      <ImprintComponent/>
    </CustomModal>

  </div>
</template>

<script>
/* eslint-disable no-console,no-underscore-dangle,no-alert */
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import { sha256 } from 'js-sha256';
import AesUtil from '@/helpers/aesBuffer';
import PromptModal from '@/components/modals/PromptModal.vue';
import OnboardingModal from '@/components/modals/OnboardingModal.vue';
import CustomModal from '@/components/modals/CustomModal.vue';
import CreateFreeTenantModal from '@/components/modals/CreateFreeTenantModal.vue';
import TermsOfUsageComponent from '@/components/TermsOfUsageComponent.vue';
import ImprintComponent from '@/components/ImprintComponent.vue';
import PrivacyComponent from '@/components/PrivacyComponent.vue';

export default {
  components: {
    PromptModal,
    CreateFreeTenantModal,
    OnboardingModal,
    CustomModal,
    TermsOfUsageComponent,
    ImprintComponent,
    PrivacyComponent,
  },
  name: 'LoginScreenComponent',
  data() {
    return {
      showLegalModal: false,
      showPrivacyModal: false,
      showImprintModal: false,
      loggedIn: false,
      showLogin: false,
      showIntro: true,
      email: '',
      password: '',
      loading: true,
      errorMessage: null,
      connectionInfo: null,
      ciLocked: false,
      tenantName: null,
      showPW: false,
      /* eslint-disable no-useless-escape */
      strongRegex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\(\)\-\._!@#\$%\^&\*\|])(?=.{8,})'),
      /* eslint-disable no-useless-escape */
      mediumRegex: new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'),
    };
  },
  async mounted() {
    // clear statically stored masterkey in crypto helper
    CryptoHelper.clearProcessedConnectionInfo();

    // check for magic
    const getVars = ServiceHelper.getVarsFromURI();
    if (getVars.ci != null) {
      this.handleMagicLink(getVars);
      return;
    }

    const storedConnectionInfo = localStorage.getItem('col_ci');
    if (storedConnectionInfo != null) {
      this.connectionInfo = storedConnectionInfo;
      this.ciLocked = true;
      this.parseConnInfo();
    } else {
      this.connectionInfo = null;
      this.ciLocked = false;
      localStorage.removeItem('col_tenant');
    }

    const storedToken = localStorage.getItem('col_log_in');
    if (storedToken != null) {
      this.token = storedToken;
      // check token!
      this.loading = true;
      try {
        const data = await ServiceHelper.loadDataFromBackend('checkToken');
        if (data.payload.user) {
          const { user, tenant } = data.payload;
          if (user.firstname != null) { user.firstname = CryptoHelper.decrypt(user.firstname); }
          if (user.lastname != null) { user.lastname = CryptoHelper.decrypt(user.lastname); }
          if (user.email != null) { user.email = CryptoHelper.decrypt(user.email); }

          this.$emit('loggedInCallback', user, tenant);

          if (data.payload.messages) {
            data.payload.messages.forEach((entry) => {
              try {
                const e = entry;
                if (entry.title != null && entry.title.length > 0) e.title = CryptoHelper.decrypt(e.title);
                if (entry.description != null && entry.description.length > 0) e.description = CryptoHelper.decrypt(e.description);
              } catch (e) {
                console.error(e);
              }
            });
            this.$emit('dayMessagesCallback', data.payload.messages);
          }
        } else {
          // sth went wrong?
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.showOnboardingIfNecessary();
    }
  },
  methods: {
    async handleMagicLink(getVars) {
      const pw4magicLink = await this.$refs.magicLinkPW.show('');
      if (pw4magicLink != null && pw4magicLink.trim().length > 0) {
        try {
          const key = CryptoHelper.createKeyFromSeed(pw4magicLink);
          this.connectionInfo = CryptoHelper.decrypt(decodeURIComponent(getVars.ci).trim(), Buffer.from(key, 'base64'));
          this.email = '';
          this.password = '';
          this.parseConnInfo();
          this.loading = false;
          this.showOnboardingIfNecessary();
        } catch (e) {
          // TODO: show error!
          window.alert('Something went wrong!');
          setTimeout(() => { this.handleMagicLink(getVars); }, 500);
        }
      } else {
        setTimeout(() => { window.location.href = '/'; }, 500);
      }
    },
    parseConnInfo() {
      if (this.connectionInfo != null) {
        const dec = JSON.parse(AesUtil.decrypt(this.connectionInfo, CryptoHelper.connectionInfoKey));
        this.tenantName = dec.tenantName;
      } else {
        this.tenantName = null;
      }
    },
    handleError(error) {
      console.error(error);
      if (error.errorCode && parseInt(error.errorCode, 10) > -1) {
        alert(this.$t(`error.code_${error.errorCode}`));
      } else {
        alert(this.$t('message.loginFailed'));
      }
      this.loggedIn = false;
      this.token = null;
      // this.errorMessage = error;
    },
    async performLogin() {
      this.loading = true;
      this.errorMessage = null;
      try {
        const dec = JSON.parse(AesUtil.decrypt(this.connectionInfo, CryptoHelper.connectionInfoKey));
        try {
          localStorage.setItem('col_tenant', dec.tenant);
          localStorage.setItem('col_server', dec.server);
        } catch (e) {
          alert(this.$t('message.missingConnectionInfo'));
          return null;
        }
        localStorage.setItem('col_ci', this.connectionInfo);
        const data = await ServiceHelper.sendDataToBackend('login', {
          email: sha256(this.email.toLowerCase()),
          password: sha256(this.password),
        });
        this.loggedIn = true;
        this.token = data.payload.token;
        const { user, tenant } = data.payload;
        if (user.firstname != null) { user.firstname = CryptoHelper.decrypt(user.firstname); }
        if (user.lastname != null) { user.lastname = CryptoHelper.decrypt(user.lastname); }
        if (user.email != null) { user.email = CryptoHelper.decrypt(user.email); }
        localStorage.setItem('col_log_in', this.token);

        console.log('WORKED! calling callback', user, tenant);

        this.$emit('loggedInCallback', user, tenant);

        if (data.payload.messages) {
          data.payload.messages.forEach((entry) => {
            try {
              const e = entry;
              if (entry.title != null && entry.title.length > 0) e.title = CryptoHelper.decrypt(e.title);
              if (entry.description != null && entry.description.length > 0) e.description = CryptoHelper.decrypt(e.description);
            } catch (e) {
              console.error(e);
            }
          });
          this.$emit('dayMessagesCallback', data.payload.messages);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
      return null;
    },
    showLoginDialog() {
      this.showIntro = false;
      this.showLogin = true;
    },
    performLogout() {
      this.loggedIn = false;
      this.token = null;
      this.reportConfigs = [];
      localStorage.removeItem('col_log_in');
      localStorage.removeItem('col_tenant');
      window.location.href = '/';
    },
    async createFreeTenant() {
      try {
        const {
          tenant,
          adminPassword,
          adminName,
          connectionInfo,
        } = await this.$refs.newTenantModal.show();
        console.log('createFreeTenant', tenant, this.adminPassword, connectionInfo);

        if (tenant != null && adminPassword != null && adminName != null && connectionInfo != null) {
          try {
            // we got the tenant information - not including the masterkey
            // so let's create key!
            const masterKey = CryptoHelper.createMasterKey();
            const parsedCI = CryptoHelper.getConnectionInfo(connectionInfo);
            parsedCI.masterKey = masterKey;
            this.connectionInfo = CryptoHelper.encrypt(JSON.stringify(parsedCI), CryptoHelper.connectionInfoKey);
            this.email = adminName;
            this.password = adminPassword;
            this.tenantName = tenant.tenantName;

            // store server so that the call can be routed to correct container
            localStorage.setItem('col_server', parsedCI.server);
            const tenantNameCrypted = CryptoHelper.encrypt(tenant.tenantName, Buffer.from(masterKey, 'base64'));
            const adminInfo = {
              firstName: CryptoHelper.encrypt('admin', Buffer.from(masterKey, 'base64')),
              lastName: CryptoHelper.encrypt('default', Buffer.from(masterKey, 'base64')),
              userName: CryptoHelper.encrypt(adminName, Buffer.from(masterKey, 'base64')),
              hashedName: sha256(adminName.toLowerCase()),
            };
            await ServiceHelper.initFreeTenant(tenant.id, tenantNameCrypted, sha256(adminPassword), adminInfo);
            localStorage.setItem('col_ci', connectionInfo);
            this.performLogin();
          } catch (e) {
            console.error(e);
          }
          // console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    showOnboardingIfNecessary() {
      if (localStorage.getItem('col_onboarding_dismissed') !== 'true') {
        this.showOnboarding();
      }
    },
    showOnboarding() {
      this.$refs.onboardingModal.show();
    },
  },
};
</script>

<style scoped>
.colada-logo {
  position: absolute;
  top: 43%;
  bottom: 43%;
  width: 40%;
  left: 50px;
}

.login-form-background-image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 60%;
  overflow: hidden;
}
.intro-wrapper {
  position: absolute;
  top: 20%;
  bottom: 28%;
  right: 0px;
  left: 50%;
  background-color: var(--color_orange_half);
  border-radius: 16px;
  height: 44%;
  min-height: 570px;
  max-width: 360px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
}

.intro-wrapper .intro-text {
  margin: 0px 16px;
  font-size: 1.1em;
  flex: 0 0;
}

.intro-wrapper .spacer {
  flex: 1 auto;
}

.intro-wrapper .actionButton {
  flex: 0 0;
  width: calc(100% - 48px);
  margin: 16px;
  box-shadow: 0px 0px 8px #888888;
  transition: all 0.1s ease-in-out;
}

.intro-wrapper .actionButton.mint {
  background-color: var(--color_mint);
}

.intro-wrapper .actionButton.white {
  background-color: white;
  color: var(--color_brown);
}

.intro-wrapper .actionButton.mint:hover {
  background-color: var(--color_brown);
  font-weight: bold;
}

.intro-wrapper .actionButton.white:hover {
  background-color: var(--color_brown);
  color: white;
  font-weight: bold;
}

.login-form-wrapper {
  position: absolute;
  top: 20%;
  bottom: 28%;
  right: 0px;
  left: 50%;
  background-color: var(--color_mint);
  border-radius: 16px;
  height: 44%;
  min-height: 570px;
  max-width: 360px;
  padding: 10px 0px;
}
.login-form-title {
  color: white;
  margin: 20px auto 40px;
  display: block;
}
.login-form-input {
  width: calc(100% - 56px);
  margin: 20px 24px 20px 24px;
  display: block;
  height: 48px;
  border-radius: 8px;
  border-width: 0px;
  font-size: 15px;
  padding-left: 10px;
}
.login-form-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color_brown);
  opacity: 1; /* Firefox */
}
.login-form-button {
  background-color: var(--color_orange);
  color: white;
  margin-right: 24px;
  border-width: 0px;
  border-radius: 8px;
  padding: 20px 40px;
  font-size: 15px;
  max-width: 50%;
}
.button-wrapper {
  text-align: right;
}

.error {
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 1000;
}

.lock {
  position: relative;
  width: 40px;
  margin-left: 24px;
  margin-top: -36px;
  text-align: left;
  cursor: pointer;
}

input:disabled {
  color: var(--color_light_gray);
}

.free-tenant-link {
  margin: 16px 24px;
  color: blue;
  cursor: pointer;
  text-align: right;
}

.free-tenant-link.float-left {
  float: left;
  text-align: left;
}

.loginBG {
  background: transparent;
}

.btnShowPassword {
  position: absolute;
  z-index: 50;
  right: 24px;
  top: 12px;
  color: white;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.txtPassword {
  width: calc(100% - 100px);
}

.legalModal {
  text-align: left;
}

.legal-link {
  cursor: pointer;
}

@media (max-width: 1024) {
}

@media (max-width: 800px) {
  .colada-logo {
    position: relative;
    left: 0; right: 0; top: 0; left: 0;
    width: 70%;
    max-width: 300px;
    margin: 16px;
  }

  .login-form-background-image {
    display: none;
  }

  .loginBG {
    background: url(../assets/login_background.png) center;
    background-size: cover;
  }

  .login-form-wrapper, .intro-wrapper {
    position: relative;
    left: 0; right: 0; top: 0; left: 0;
    width: 70%;
    margin: 16px auto;
    max-width: 360px;
  }
}
</style>
