<template>
  <CustomModal v-model="showFreeTenantModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{$t('modal.titleFreeTenant')}}</template>
    <div class="form">
      <div class="row" >
        <div class="label">{{ $t('modal.labelTenantName') }}:</div>
        <div class="value"><input v-model="tenantName" type="text"/>
          <br/><div v-if="errorTenantName != null" class="fieldError">{{errorTenantName}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelAdminName') }}:</div>
        <div class="value"><input v-model="adminName" :type="text"/>
          <br/><div v-if="errorAdminName != null" class="fieldError">{{errorAdminName}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelAdminPassword') }}:</div>
        <div class="value"><input v-model="adminPassword" :type="showPassword ? 'text' : 'password'"/>
          <div v-if="showPassword" class="showPassword" data-cy="hidePassword" @click="showPassword = !showPassword"><i class="fas fa-eye"/></div>
          <div v-if="!showPassword" class="showPassword" data-cy="showPassword" @click="showPassword = !showPassword"><i class="fas fa-eye-slash"/></div>
          <br/><div v-if="errorAdminPassword != null" class="fieldError">{{errorAdminPassword}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelPasswordConfirmation') }}:</div>
        <div class="value"><input v-model="passwordConfirmation" :type="showPassword ? 'text' : 'password'"/>
          <br/><div v-if="errorPasswordConfirmation != null" class="fieldError">{{errorPasswordConfirmation}}</div>
        </div>
      </div>
      <div class="row" v-if="captcha != null">
        <div class="label">{{ $t('modal.labelCaptcha') }}:</div>
        <div class="value">
          <input v-model="captchaResult" type="text"/>
          <br/><div v-if="errorCaptcha != null" class="fieldError">{{errorCaptcha}}</div><br/>
          <svg v-html="captcha.svgData"/>
          <div class="changeCaptcha" @click="loadCaptcha"><i class="fas fa-sync"/></div>
        </div>
      </div>
      <div class="row">
        <div class="acceptDiv">
          <input v-model="termsAccepted" type="checkbox"/>
          <div v-html="$t('modal.txtAcceptTerms')"/>
        </div>
        <div v-if="errorTermsAccepted != null" class="fieldError">{{errorTermsAccepted}}</div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '../../helpers/ServiceHelper';

export default {
  name: 'CreateFreeTenantModal',
  props: [],
  components: {
    CustomModal,
  },
  data() {
    return {
      termsAccepted: false,
      tenantName: null,
      adminName: null,
      adminPassword: null,
      passwordConfirmation: null,
      showPassword: false,
      errorTenantName: null,
      errorAdminName: null,
      errorAdminPassword: null,
      errorPasswordConfirmation: null,
      errorCaptcha: null,
      errorTermsAccepted: null,
      captcha: null,
      captchaResult: null,
      showFreeTenantModal: false,
      /* eslint-disable no-useless-escape */
      strongRegex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\(\)\-\._!@#\$%\^&\*\|])(?=.{8,})'),
      resolve: null,
      reject: null,
      promise: null,
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    async loadCaptcha() {
      try {
        this.captcha = (await ServiceHelper.getCaptcha()).payload;
      } catch (e) {
        alert('Could not load captcha!');
        console.error(e);
      }
    },
    async show() {
      this.loadCaptcha();
      this.showPassword = false;
      this.tenantName = null;
      this.adminPassword = null;
      this.termsAccepted = false;
      this.captchaResult = null;
      this.passwordConfirmation = null;
      this.errorTenantName = null;
      this.errorAdminName = null;
      this.errorAdminPassword = null;
      this.errorPasswordConfirmation = null;
      this.errorCaptcha = null;
      this.errorTermsAccepted = null;
      this.showFreeTenantModal = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    hide() {
      this.showFreeTenantModal = false;
    },
    async confirm() {
      this.errorTenantName = null;
      this.errorAdminName = null;
      this.errorAdminPassword = null;
      this.errorPasswordConfirmation = null;
      this.errorTermsAccepted = null;
      this.errorCaptcha = null;

      let hasErrors = false;
      if (this.tenantName == null || this.tenantName.trim().length < 5) {
        this.errorTenantName = this.$t('validationError.errorTenantNameEmpty');
        hasErrors = true;
      }

      if (this.adminName == null || this.adminName.trim().length < 3) {
        this.errorAdminName = this.$t('validationError.errorAdminNameTooShort');
        hasErrors = true;
      }

      // check rules
      if (!this.strongRegex.test(this.adminPassword)) {
        this.errorAdminPassword = this.$t('validationError.errorEmptyOrInsufficientPassword');
        hasErrors = true;
      }

      // check rules
      if (this.adminPassword !== this.passwordConfirmation) {
        this.errorPasswordConfirmation = this.$t('validationError.errorPasswordsDontMatch');
        hasErrors = true;
      }

      // check captcha
      if (this.captchaResult == null || this.captchaResult.trim().length === 0) {
        this.errorCaptcha = this.$t('validationError.errorCaptcha');
        hasErrors = true;
      }

      if (!this.termsAccepted) {
        this.errorTermsAccepted = this.$t('validationError.errorTermsNotAccepted');
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      try {
        const data = await ServiceHelper.createFreeTenant(this.tenantName, { entered: this.captchaResult, cryptedText: this.captcha.cryptedText });
        const { tenant, connectionInfo } = data.payload;
        this.resolve({
          tenant: { ...tenant, tenantName: this.tenantName },
          adminName: this.adminName,
          adminPassword: this.adminPassword,
          connectionInfo,
        });
      } catch (error) {
        console.error(error);
        if (error.errorCode && parseInt(error.errorCode, 10) > -1) {
          // alert(this.$t(`error.code_${error.errorCode}`));
          this.errorCaptcha = this.$t(`error.code_${error.errorCode}`);
        } else {
          alert(this.$t('error.general'));
        }
      }
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style scoped>
::v-deep(svg) {
  width: 150px;
  height: 50px;
}
::v-deep(.changeCaptcha) {
  cursor: pointer;
  display: inline-block;
  margin-left: 4px;
}

::v-deep(.acceptDiv) {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
</style>
