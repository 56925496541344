/* eslint-disable */
module.exports = {
  "noParagraphs": true,
  "intro": "",
  "paragraphs": [
    {
      "title": "Definitionen",
      "sections": [
        "Diese Hinweise geben Ihnen als Nutzer unserer Dienste ('Kunde') Auskunft über die Daten-sammlung und -verarbeitung welche wir, die app affairs GmbH ('Anbieter'), im Rahmen der Nutzung unserer Dienste rund um die Collada Plattform durchführen.",
      ]
    },
    {
      "title": "Wirksamkeitsbereich und Abgrenzung",
      "sections": [
        "Der Schutz Ihrer persönlichen Daten ist für uns sehr wichtig und wir behandeln Ihre personen-bezogenen Daten sorgfältig, vertraulich und gemäß den Bestimmungen der DSGVO und dieser Datenschutzhinweise. Mit dieser Seite klären wir Sie über Art, Umfang und Zweck der von uns über Sie bei der Verwendung unserer Dienste erhobenen, genutzten und verarbeiteten personenbezogenen Daten. Darüber hinaus erklären wir Ihnen Ihre Recht und wie Sie diese geltend machen bzw. an wen Sie sich wenden können. Diese Hinweise gelten für alle Webseiten, Portal, Apps und Dienste rund um die Collada-Plattform.",
        "Alle Daten, die Sie bei der Verwendung unserer Dienste aktiv erfassen, hochladen, individuell gestalten, etc. werden mit einem Ende-zu-Ende-Verfahren ausschließlich in Ihrem Endgerät (Smartphone oder Browser) ver- und entschlüsselt. Den hierzu notwendigen kryptografischen Schlüssel kennen nur Sie und Ihre Mitarbeiter, denen Sie Zugriff auf Ihr Benutzerkonto einräumen und denen Sie diese Informationen per QR-Code oder in Textform zukommen lassen. Auch der Anbieter der Collada-Dienste kann die so von Ihnen verschlüsselten Daten nicht lesen.",,
        "Die Ausführungen zur Sammlung und Verarbeitung von Daten in diesen Hinweisen beziehen sich daher explizit nicht auf die durch den Nutzer aktiv in der Collada-Plattform verschlüsselt gespeicherten Daten, sondern auf die aus technischen oder rechtlichen Gründen zusätzlich zu erfassenden Daten, welche vom Anbieter lesbar und daher sinnvoll zu verarbeiten sind.",
        "Die Collada-Plattform richtet sich ausschließlich an Unternehmenskunden im Sinne des §14 BGB.",
        "Unsere Server werden von unseren Hosting-Partnern und Auftragsdatenverarbeitern in deutschen Rechenzentren betrieben. Wenn von Weitergabe von Daten an Dritten die Rede ist, sind unsere Hosting-Partner welche in unserem Auftrag und per Auftragsdatenverarbeitungsvereinbarung gebunden die Server sowie die umgebende Infrastruktur zum Betrieb unserer Dienste bereitstellen, ausgenommen.",
      ]
    },
    {
      "title": "Art und Umfang der erfassten Daten",
      "sections": [
        "Erläuterung: Dem Begriff 'Personenbezogene Daten' liegt im Folgenden die allgemeine Definiti-on dieser Daten der Europäischen Kommission (vgl. https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-personal-data_de) zu Grunde.",
        "",
        "<strong>3.1 Webseite, Portal und Apps</strong>",
        "Die Collada-Webseite sowie das Collada-Portal und die Collada-App sind ohne Angabe personenbezogener Daten möglich. Zur Verarbeitung der Anfragen von Ihrem Browser bzw. Ihrem mobilen Endgerät wird jedoch ein Datensatz an unsere Server übermittelt bzw. von unseren Systemen erfasst, welcher folgende Informationen beinhaltet:",
        "<li>IP-Adresse (mobil und Browser)</li>",
        "<li>Datum und Uhrzeit der Anfrage (mobil und Browser)</li>",
        "<li>Zeitzonendifferenz zur Greenwich Mean Time (mobil und Browser)</li>",
        "<li>Inhalt der Anforderung (konkrete Seite, mobil und Browser)</li>",
        "<li>Zugriffsstatus/HTTP-Statuscode (mobil und Browser)</li>",
        "<li>jeweils übertragene Datenmenge (mobil und Browser)</li>",
        "<li>Website, von der die Anforderung kommt (Browser)</li>",
        "<li>Browser bzw. HTTP-Agent (mobil und Browser)</li>",
        "<li>Betriebssystem und dessen Oberfläche (Browser)</li>",
        "<li>Sprache und Version der Browsersoftware (Browser).</li>",
        "Diese an uns übermittelten technischen Informationen über gestellte Anfragen werden anonymisiert (Maskieren des letzten Blocks der IP-Adresse) in sogenannten Log-Files gespeichert und zur Analyse eventueller Fehler sowie ggf. zur Untersuchung und Abwehr von Cyber-Attacken für eine Dauer von 3 Monaten gespeichert. Aus diesen Daten werden keine Rückschlüsse auf die betroffene Person abgeleitet und die Daten werden nicht an Dritte weitergegeben.",
        "",
        "<strong>3.2 E-Mail-Anfragen</strong>",
        "Wenn Sie uns per E-Mail oder auf sonstige Weise Anfragen zukommen lassen, werden Ihre Angaben inklusive der von Ihnen angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Angaben durch Sie erfolgen stets auf freiwilliger Basis. Ihre Daten geben wir nicht ohne Ihre Einwilligung weiter. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist daher nicht möglich.",
        "",
        "<strong>3.3 Registrierung auf unserer Website, Abonnements, Zahlungsdaten</strong>",
        "Sie haben die Möglichkeit, unseren Collada Service auf Basis einer kostenlosen Testphase zu prüfen. Zur Aktivierung Ihres kostenlosen Testzugangs müssen keine personenbezogenen Daten angegeben werden, jedoch wird wie bei allen anderen technischen Anfragen an unsere Systeme der unter Ziffer 3.1 genannte Satz an Informationen erfasst.",
        "Darüber hinaus können Sie sich entscheiden, ein kostenpflichtiges Abonnement über die Nutzung unserer Diente abzuschließen. Im Rahmen der Umwandlung Ihres Nutzungskontos in ein kostenpflichtiges Abonnement müssen wir personenbezogene Daten von Ihnen erfassen, um den Anforderungen an eine ordnungsgemäße Rechnungsstellung zu genügen. Die erfassten Daten ergeben sich aus den in der Anmeldemaske zu treffenden Eingaben. Diese Daten werden nach Stand der Technik verschlüsselt abgelegt, können aber vom Anbieter jederzeit entschlüsselt werden. Wir werden auch die hier erfasste Kontakt-E-Mail als Kommunikationskanal verwenden, um Sie über anstehende oder getätigte Zahlungen oder Probleme bei der Abwicklung von wiederkehrenden Zahlungen zu informieren.",
        // "Zur Durchführung von Zahlungen leiten wir Sie auf die Dienste und Server unseres Zahlungsdienstleisters mollie weiter. Wir gelangen nie in die Kenntnis der von Ihnen auf den Seiten von mollie eingegebenen Zahlungsinformationen, nach abgeschlossener Zahlung erlangen wir lediglich Kenntnis über Erfolg oder Misserfolg der Transaktion. Über den Umgang mit Ihren Zahlungsinformationen bei mollie können Sie sich hier informieren: https://www.mollie.com/de/privacy.",
      ]
    },
    {
      "title": "Weitergabe an Dritte",
      "sections": [
        "Eine Weitergabe Ihrer personenbezogenen Daten ohne Ihre ausdrückliche vorherige Einwilligung erfolgt neben den explizit in dieser Datenschutzerklärung genannten Fällen lediglich dann, wenn es gesetzlich zulässig bzw. erforderlich ist. Dies kann u.a. der Fall sein, wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des Nutzers oder einer ande-ren natürlichen Person zu schützen.",
        "<strong>4.1 Verwendung innerhalb des Unternehms</strong>",
        "Die Daten, die bei der Registrierung von Ihnen angegeben werden, werden innerhalb unserer Unternehmensgruppe für interne Verwaltungszwecke einschließlich der gemeinsamen Kundenbetreuung im Rahmen des Erforderlichen weitergeben.",
        "<strong>4.2 Aufklärung rechtsidriger oder missbräuchlicher Nutzung</strong>",
        "Wenn es zur Aufklärung einer rechtswidrigen bzw. missbräuchlichen Nutzung der Plattform oder für die Rechtsverfolgung erforderlich ist, werden personenbezogene Daten an die Strafverfolgungsbehörden oder andere Behörden sowie ggf. an geschädigte Dritte oder Rechtsberater weitergeleitet. Dies geschieht jedoch nur, wenn Anhaltspunkte für ein gesetzwidriges bzw. missbräuchliches Verhalten vorliegen. Eine Weitergabe kann auch stattfinden, wenn dies der Durchsetzung von Nutzungsbedingungen oder anderen Rechtsansprüchen dient. Wir sind zudem gesetzlich verpflichtet, auf Anfrage bestimmten öffentlichen Stellen Auskunft zu erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die bußgeldbewährte Ordnungswidrigkeiten verfolgen, und die Finanzbehörden. Sollten wir seitens Strafverfolgungsbehörden, staatlicher Stellen oder per Gesetz autorisierter Dritter durch Auskunftsersuchen oder im Rahmen von Ermittlungsverfahren bzw. des Verdachts auf eine vorliegende Straftat oder rechtswidrige oder haftungsrechtlich relevante Handlung zur Herausgabe von personenbezogenen Daten aufgefordert werden, kommen wir dieser Aufforderung nur mit unserer Einschätzung nach relevanten Daten wie z.B. Name, Ort, Postleitzahl, E-Mail-Adresse und IP-Adresse nach.",
        "Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt, dass (1) die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der wir gemäß Art. 6 Abs. 1 lit. f) DSGVO i.V.m. nationalen rechtlichen Vorgaben zur Weitergabe von Daten an Strafverfolgungsbehörden unterliegen, oder (2) wir ein be-rechtigtes Interesse daran haben, die Daten bei Vorliegen von Anhaltspunkten für missbräuchliches Verhalten oder zur Durchsetzung unserer Nutzungsbedingungen, anderer Bedingungen oder von Rechtsansprüchen an die genannten Dritten weiterzu-geben und Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f) DSGVO nicht überwiegen.",
        "<strong>4.3 Externe Dienstleister</strong>",
        "Wir sind für die Erbringung unseres Dienstes auf externe Dienstleister angewiesen. Rechnungsunterlagen und die von Ihnen angegebenen Abrechnungsdaten (Ansprechpartner, Rechnungsanschrift und ggf. USt-Nr.) geben wir zu Zwecken der Buchhaltung und Bilanzierung an unsere Steuerberatungskanzlei als externer Dienstleister weiter.",
        "Falls notwendig, behalten wir uns eine Weitergabe Ihrer erfassten Daten im Rahmen der Gefahrenabwehr oder der Klärung bzw. Abwehr rechtlicher Auseinandersetzungen und Forderungen an externe Dienste vor.",
        "Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt, dass (1) wir ein berechtigtes Interesse daran haben, die Daten für administrative Zwecke innerhalb unserer Unternehmensgruppe weiterzugeben und Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f) DSGVO nicht überwiegen und (2) wir unsere Fremdunternehmen und externen Dienstleister im Rahmen von Art. 28 Abs. 1 DSGVO als Auftragsverarbeiter sorgfältig ausgewählt, regelmäßig überprüft und vertraglich verpflichtet haben, sämtliche personenbezogenen Daten ausschließlich entsprechend unserer Weisungen zu verarbeiten.",
        "<strong>4.4 Unternehmensentwicklung</strong>",
        "Im Rahmen der Weiterentwicklung unseres Geschäfts kann es dazu kommen, dass sich die Struktur unseres Unternehmens wandelt, indem die Rechtsform geändert wird, Tochtergesellschaften, Unternehmensteile oder Bestandteile gegründet, gekauft oder verkauft werden. Bei solchen Transaktionen werden die Kundeninformationen gegebenenfalls zusammen mit dem zu übertragenden Teil des Unternehmens weitergegeben. Bei jeder Weitergabe von personenbezogenen Daten an Dritte in dem vorbeschriebenen Umfang tragen wir dafür Sorge, dass dies in Übereinstimmung mit dieser Datenschutzerklärung und dem anwendbaren Datenschutzrecht erfolgt.",
        "Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt, dass wir ein berechtigtes Interesse daran haben, unsere Unternehmensform den wirtschaftlichen und rechtlichen Gegebenheiten entsprechend bei Bedarf anzupassen und Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f) DSGVO nicht überwiegen.",
      ]
    },
    {
      "title": "Zweckänderungen",
      "sections": [
        "Verarbeitungen Ihrer personenbezogenen Daten zu anderen als den beschriebenen Zwecken erfolgen nur, soweit eine Rechtsvorschrift dies erlaubt oder Sie in den geänderten Zweck der Datenverarbeitung eingewilligt haben. Im Falle einer Weiterverarbeitung zu anderen Zwecken als denen, für den die Daten ursprünglich erhoben worden sind, informieren wir Sie vor der Weiterverarbeitung über diese anderen Zwecke und stellen Ihnen sämtliche weitere hierfür maßgeblichen Informationen zur Verfügung.",
      ]
    },
    {
      "title": "Zeitraum der Datenspeicherung",
      "sections": [
        "Wir löschen oder anonymisieren Ihre personenbezogenen Daten, sobald sie für die Zwecke, für die wir sie nach den vorstehenden Ziffern erhoben oder verwendet haben, nicht mehr erforderlich sind. In der Regel speichern wir Ihre personenbezogenen Daten für die Dauer des Nutzungs- bzw. des Vertragsverhältnisses bezüglich der Collada-Plattform zzgl. eines Zeitraumes von 7 Tagen, während welchem wir nach der Löschung Sicherungskopien aufbewahren, soweit diese Daten nicht für die strafrechtliche Verfolgung oder zur Sicherung, Geltendmachung oder Durchsetzung von Rechtsansprüchen länger benötigt werden.",
        "Spezifische Angaben in dieser Datenschutzerklärung oder rechtliche Vorgaben zur Aufbewahrung und Löschung personenbezogener Daten, insbesondere solcher, die wir aus steuerrechtlichen Gründen aufbewahren müssen, bleiben unberührt.",
      ]
    },
    {
      "title": "Ihre Rechte als Betroffener",
      "sections": [
        "<strong>7.1 Auskunftsrecht</strong>",
        "Sie haben das Recht, von uns jederzeit auf Antrag eine Auskunft über die von uns verarbeiteten, Sie betreffenden personenbezogenen Daten im Umfang des Art. 15 DSGVO zu erhalten. Hierzu können Sie einen Antrag postalisch oder per E-Mail an die unten angegebene Adresse stellen.",
        "<strong>7.2 Auskunftsrecht</strong>",
        "Sie haben gem. Art. 16 DSGVO das Recht, von uns die unverzügliche Berichtigung der Sie betreffenden personenbezogenen Daten zu verlangen, sofern diese unrichtig sein sollten. Wenden Sie sich hierfür bitte an die unten angegebenen Kontaktadressen.",
        "<strong>7.3 Recht auf Löschung</strong>",
        "Sie haben das Recht, unter den in Art. 17 DSGVO beschriebenen Voraussetzungen von uns die Löschung der Sie betreffenden personenbezogenen Daten zu verlangen. Diese Voraussetzungen sehen insbesondere ein Löschungsrecht vor, wenn die personenbezogenen Daten für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind, sowie in Fällen der unrechtmäßigen Verarbeitung, des Vorliegens eines Widerspruchs oder des Bestehens einer Löschungspflicht nach Unionsrecht oder dem Recht des Mitgliedstaates, dem wir unterliegen. Zum Zeitraum der Datenspeicherung siehe im Übrigen Ziffer 4 dieser Datenschutzerklärung. Um Ihr Recht auf Löschung geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.",
        "<strong>7.4 Recht auf Einschränkung der Verarbeitung</strong>",
        "Sie haben das Recht, von uns die Einschränkung der Verarbeitung nach Maßgabe des Art. 18 DSGVO zu verlangen. Dieses Recht besteht insbesondere, wenn die Richtigkeit der personenbezogenen Daten zwischen dem Nutzer und uns umstritten ist, für die Dauer, welche die Überprüfung der Richtigkeit erfordert, sowie im Fall, dass der Nutzer bei einem bestehenden Recht auf Löschung anstelle der Löschung eine eingeschränkte Verarbeitung verlangt; ferner für den Fall, dass die Daten für die von uns verfolgten Zwecke nicht länger erforderlich sind, der Nutzer sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt sowie, wenn die erfolgreiche Ausübung eines Widerspruchs zwischen uns und dem Nutzer noch umstritten ist. Um Ihr Recht auf Einschränkung der Verarbeitung geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.",
        "<strong>7.5 Recht auf Datenübertragbarkeit</strong>",
        "Sie haben das Recht, von uns die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen, maschinenlesbaren Format nach Maßgabe des Art. 20 DSGVO zu erhalten. Um Ihr Recht auf Datenübertragbarkeit geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.",
      ]
    },
    {
      "title": "Widerspruchsrecht",
      "sections": [
        "<strong>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die u.a. aufgrund von Art. 6 Abs. 1 lit. e) oder f) DSGVO erfolgt, Widerspruch nach Art. 21 DSGVO einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.</strong>",
        "Falls Sie der beschriebenen Verarbeitung Ihrer personenbezogenen Daten in Teilen oder in Gänze widersprechen, oder Sie Ihre Einwilligung zur Verarbeitung widerrufen, ist es u. U. nicht mehr möglich, Ihnen alle Services - wie beschrieben - zur Verfügung zu stellen. Gegebenenfalls entbindet dies Sie nicht automatisch von allen im Rahmen der kostenpflichtigen Nutzung vereinbarten Zahlungsverpflichtungen.",
      ]
    },
    {
      "title": "Beschwerderecht",
      "sections": [
        "Sie haben ferner das Recht, sich bei Beschwerden an die zuständige Aufsichtsbehörde zu wenden. Die zuständige Aufsichtsbehörde ist: <strong>Bayerisches Landesamt für Datenschutzaufsicht (BayLDA) Promenade 18, 91522 Ansbach Tel.: +49 (0) 981 180093-0, Telefax: +49 (0) 981 180093-800,  E-Mail: poststelle@lda.bayern.de</strong>",
      ]
    },
    {
      "title": "Schutz Ihrer persönlichen Daten",
      "sections": [
        "Wir setzen auf technische und organisatorische Maßnahmen, um Ihre Daten vor Risiken hinsichtlich Verlust, Missbrauch, unberechtigtem Zugriff und Weitergabe zu schützen. Hierzu zählen z.B. der Einsatz von Firewalls, Datenverschlüsselung sowie die Einschränkung von Zugriffsberechtigungen auf die involvierte Infrastruktur und verwendeten Server.",
      ]
    },
    {
      "title": "Kontakt",
      "sections": [
        "Sollten Sie Fragen oder Anmerkungen zu unserem Umgang mit Ihren personenbezogenen Daten haben oder möchten Sie die unter Ziffer 7 und 8 genannten Rechte als betroffene Per-son ausüben, wenden Sie sich bitte an die app affairs GmbH, unter folgenden Kontaktdaten: Klenzestr. 13, 80469 München, Tel. +49 (0) 89 23795795, E-Mail: collada@app-affairs.com, Webseite: www.app-affairs.com.",
        "Unser Datenschutzbeauftragter ist unter folgenden Kontaktdaten zu erreichen: Herr Steffen Blass, app affairs GmbH, Klenzestr. 13, 80469 München, Tel. +49 (0) 89 23795795, E-Mail: datenschutz@app-affairs.com",
      ]
    },
    {
      "title": "Änderungen dieser Datenschutzerklärung",
      "sections": [
        "Wir halten diese Datenschutzerklärung immer auf dem neuesten Stand. Deshalb behalten wir uns vor, sie von Zeit zu Zeit zu ändern und Änderungen bei der Erhebung, Verarbeitung oder Nutzung Ihrer Daten nachzupflegen. Die aktuelle Fassung der Datenschutzerklärung ist unter Angabe des Zeitpunkts des Wirksamwerdens stets unter „Datenschutz“ innerhalb der Collada-Plattform abrufbar. Über wesentliche Änderungen setzen wir Sie ggf. per E-Mail in Kenntnis.",
      ]
    },
  ]
};

