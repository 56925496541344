const langEn = require('./lang_en');
const langDe = require('./lang_de');

module.exports = {
  LocaleEN: langEn,
  LocaleDE: langDe,
  getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: true };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale = navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
    if (!navigatorLocale) {
      return undefined;
    }
    const trimmedLocale = opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim();
    // eslint-disable-next-line no-console
    // console.log('locale:', trimmedLocale);
    return trimmedLocale;
  },
  getStandardReportLanguages() {
    return {
      de: 'Deutsch',
      en: 'English',
      fr: 'Français',
      es: 'Español',
      pt: 'Português',
    };
  },
};
