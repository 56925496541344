/* eslint-disable no-console */
const axios = require('axios');
const uuidv4 = require('uuid').v4;
const CryptoHelper = require('./CryptoHelper');

const getToken = () => {
  const storedToken = localStorage.getItem('col_log_in');
  let token = '';
  if (storedToken != null) {
    token = storedToken;
  }
  return token;
};

const storeToken = (tk) => {
  localStorage.getItem('col_log_in', tk);
};

const getDeviceUuid = () => {
  const storedUuid = localStorage.getItem('col_uuid');
  let devUuid = '';
  if (storedUuid != null) {
    devUuid = storedUuid;
  } else {
    devUuid = uuidv4();
    localStorage.setItem('col_uuid', devUuid);
  }
  return devUuid;
};

const performCall = async (endPoint, options, baseURL = null) => {
  try {
    // console.log('performing call', options);
    const hostURL = baseURL != null ? baseURL : process.env.VUE_APP_COLADA_BASE_URL;
    // console.log(`Perform call to ${hostURL}${endPoint} with method ${options.method}`);
    const response = await fetch(`${hostURL}/${endPoint}`, options);
    const data = await response.json();
    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      // console.log(response.status);
      if (response.status === 401) {
        // unauthorized
        localStorage.removeItem('col_log_in');
        localStorage.removeItem('col_user');
        localStorage.removeItem('col_tenant');
        window.location.href = '/';
      }
      const error = data && data.msg != null ? data : response.status;
      throw (error);
    }
    return data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

const getTenant = () => localStorage.getItem('col_tenant');
const getServerForTenant = () => localStorage.getItem('col_server');

const sendDataToBackend = async (endPoint, payload, method = 'POST', callback) => {
  // console.log(`Perform send call to ${endPoint} with method ${method}`);
  try {
    const stringPayload = JSON.stringify(payload);
    const response = await axios.request({
      method,
      headers: {
        'Content-Type': 'application/json',
        coladatoken: getToken(),
        coladaproduct: 'web',
        deviceuuid: getDeviceUuid(),
        devicename: CryptoHelper.encrypt(navigator.userAgent),
        devicetype: 'BROWSER',
        tenant: getTenant(),
        coladaserver: getServerForTenant(),
      },
      url: `${process.env.VUE_APP_COLADA_BASE_URL}/${endPoint}`,
      data: stringPayload,
      onUploadProgress: (p) => {
        if (callback) callback(p);
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.errorCode) {
      throw error.response.data;
    } else {
      throw (error);
    }
  }
};

const sendDataToAdminBackend = async (endPoint, payload, method = 'POST', callback) => {
  // console.log(`Perform send call to ${endPoint} with method ${method}`);
  try {
    const stringPayload = JSON.stringify(payload);
    const response = await axios.request({
      method,
      headers: {
        'Content-Type': 'application/json',
        apitoken: 'LxMvjja1fcfN1XJQjjvn9e+trE3tecw7gjRVU51xWG8=',
        coladaserver: 'admin',
      },
      url: `${process.env.VUE_APP_COLADA_ADMIN_BASE_URL}/${endPoint}`,
      data: stringPayload,
      onUploadProgress: (p) => {
        if (callback) callback(p);
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.errorCode) {
      throw error.response.data;
    } else {
      throw (error);
    }
  }
};

const loadDataFromBackend = async (endPoint, additionalHeaders) => {
  try {
    const headers = {
      coladatoken: getToken(),
      coladaproduct: 'web',
      devicetype: 'BROWSER',
      deviceuuid: getDeviceUuid(),
      devicename: CryptoHelper.encrypt(navigator.userAgent),
      tenant: getTenant(),
      coladaserver: getServerForTenant(),
    };
    if (additionalHeaders != null) {
      Object.keys(additionalHeaders).forEach((key) => {
        headers[key] = additionalHeaders[key];
      });
    }
    const requestOptions = {
      method: 'GET',
      headers,
    };
    return await performCall(endPoint, requestOptions);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

module.exports = {
  getToken,
  storeToken,
  getDeviceUuid,
  getTenant,
  getServerForTenant,
  loadDataFromBackend,
  sendDataToBackend,
  deleteDataFromBackend: async (endPoint) => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          coladatoken: getToken(),
          coladaproduct: 'web',
          deviceuuid: getDeviceUuid(),
          devicename: CryptoHelper.encrypt(navigator.userAgent),
          devicetype: 'BROWSER',
          tenant: getTenant(),
          coladaserver: getServerForTenant(),
        },
      };
      return await performCall(endPoint, requestOptions);
    } catch (error) {
      console.error(error);
      throw (error);
    }
  },
  createFreeTenant: async (tenantName, captcha) => {
    try {
      return await sendDataToAdminBackend(`createTenantFromApp/${encodeURIComponent(tenantName)}`, captcha);
    } catch (error) {
      console.error(error);
      throw (error);
    }
  },
  getCaptcha: async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          apitoken: 'LxMvjja1fcfN1XJQjjvn9e+trE3tecw7gjRVU51xWG8=',
          coladaserver: 'admin',
        },
      };
      return await performCall('captcha', requestOptions, process.env.VUE_APP_COLADA_ADMIN_BASE_URL);
    } catch (error) {
      console.error(error);
      throw (error);
    }
  },
  initFreeTenant: async (tenantId, tenantName, adminPassword, adminInfo) => {
    try {
      const res = await sendDataToBackend('freeTenant', {
        tenantId,
        tenantName,
        adminPassword,
        hashedAdminName: adminInfo.hashedName,
        adminName: adminInfo.userName,
        adminFirstName: adminInfo.firstName,
        adminLastName: adminInfo.lastName,
      });
      return res;
    } catch (error) {
      console.error(error);
      throw (error);
    }
  },
  getVarsFromURI: () => {
    const getVars = {};
    try {
      const seperated = window.location.href.split('#');
      const uri = seperated[0].split('?');
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) {
            // eslint-disable-next-line prefer-destructuring
            getVars[tmp[0]] = tmp[1].replace('/', '').replace('#', '');
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
    return getVars;
  },
};
