<template>
  <CustomModal v-model="showModal" @confirm="confirm" showCancel="">
    <template v-slot:title>Collada Onboarding</template>
    <div class="onboarding-content">
      <Carousel ref="carousel" :items-to-show="1">
        <Slide v-for="(slide, index) in slides" :key="index">
          <div class="onboarding-slide">
            <div class="onboarding-title">
              {{slide.title}}
            </div>
            <div class="onboarding-icon">
              <img :src="requireImg(slide.icon)"/>
            </div>
            <div class="onboarding-text" v-html="slide.text">
            </div>
          </div>
        </Slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'OnboardingModal',
  props: [],
  components: {
    CustomModal,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      showModal: false,
      slides: [
        {
          title: 'Willkommen zu Collada - "Collect any data"!',
          text: 'Collada unterstützt Sie bei der Umwandlung Ihrer analogen Geschäftsprozesse in digitale Abläufe.<br/><br/>'
          + 'Dabei besteht Collada aus zwei Teilen:<br/>- der Collada-App und<br/>- dem Collada-Portal.',
          icon: 'colada_icon.png',
        },
        {
          title: 'Die Collada-App',
          text: 'Mit der Collada-App sammeln Sie direkt bei Ihren Kunden vor Ort die relevanten Daten, Fotos, etc.'
          + 'und diese werden automatisch und so schnell wie möglich an Ihr zentrales Collada-Portal übertragen.<br/>'
          + 'Daneben haben Sie in Ihrer Collada-App jederzeit Zugriff auf die im Collada-Portal abgelegten Informationen '
          + 'wie z.B. aktuelle Preislisten oder Handbücher.',
          icon: 'onboarding/onboarding_mobile.png',
        },
        {
          title: 'Das Collada-Portal',
          text: 'Im Collada-Portal definieren Sie in Formularen, welche Informationen Sie beim Kunden einsammeln möchten, '
          + 'legen Benutzer und -gruppen an und laden die aktuellen Dateien wie z.B. Preislisten für Ihre Mitarbeiter hoch.<br/><br/>'
          + 'Im Collada-Portal werden alle von Ihren Mitarbeitern erfassten Daten gesammelt und können dort eingesehen, durchsucht '
          + 'und exportiert werden.',
          icon: 'onboarding/onboarding_portal.png',
        },
        {
          title: 'App und Portal koppeln',
          text: 'Die App für iOS und Android laden Sie ganz einfach und bequem im jeweiligen Store herunter.<br/><br/>'
          + 'Damit die App anschließend mit Ihrem Portal gekoppelt wird, scannen Sie einfach mit Ihrem Smartphone den '
          + 'QR-Code Ihres Portals und können sich dann sofort in der App mit Ihrem Benutzer anmelden.',
          icon: 'onboarding/onboarding_qr.png',
        },
        {
          title: 'Noch kein Mandant?',
          text: 'Sie haben die App im Store entdeckt und noch keinen Mandanten im Portal angelget?<br/><br/>'
          + 'Kein Problem!<br/><br/>'
          + 'Sie können einfach auf dem Anmeldeschirm der App einen neuen Mandanten anlegen und direkt loslegen!<br/>'
          + 'Die App bietet Ihnen dann im Info-Bereich bequemen Zugriff auf Ihren Bereich im Collada-Portal!',
          icon: 'onboarding/phone_preview.png',
        },
        {
          title: 'Ende-zu-Ende verschlüsselt',
          text: 'Ihre Daten werden in unseren Datenbanken komplett verschlüsselt abgelegt. Den Schlüssel zu Ihren Daten haben '
          + 'nur Sie - in Ihrem QR-Code bzw. Ihrem Verbindungscode.<br/><br/>'
          + '<strong>Wichtig: Bewahren Sie diesen Code als QR-Code und als Text sicher auf! Ohne Ihren Code können Sie nicht mehr auf '
          + 'Ihre Daten zugreifen und auch wir können diese nicht wieder lesbar machen!</strong>',
          icon: 'onboarding/onboarding_security.png',
        },
        {
          title: 'Einfach zusammenarbeiten!',
          text: 'Um mit Ihren Mitarbeitern und Kollegen zusammenzuarbeiten, legen Sie einfach im Portal Benutzer an und lassen '
          + 'Sie Ihre Mitarbeiter per App den QR-Code scannen oder schicken Sie Ihnen den QR-Code oder Text-Code zu.<br/><br/>'
          + 'Natürlich können Sie bestimmen, welcher Benutzer Zugriff auf welche Formulare und Dateien haben.',
          icon: 'onboarding/onboarding_mail.png',
        },
        {
          title: 'Geschafft!',
          text: 'Das waren die wichtigsten Informationen, damit Sie direkt erfolgreich mit Collada starten können.<br/><br/>'
          + 'Auf unserer Webseite www.collada.app können Sie jederzeit alles nochmals nachlesen oder <br/>'
          + 'sich die ersten Schritte mit Collada in einem Video anschauen.<br/><br/>'
          + 'Viel Spaß und Erfolg für Ihre digitale Transformation mit Collada!',
          icon: 'colada_icon.png',
        },
      ],
    };
  },
  methods: {
    async show() {
      console.log(this.$refs.carousel);
      this.showModal = true;
      this.$nextTick(() => {
        this.$refs.carousel.updateSlideWidth();
      });
    },
    requireImg(icon) {
      // eslint-disable-next-line
      return require(`../../assets/${icon}`);
    },
    hide() {
      this.showModal = false;
      localStorage.setItem('col_onboarding_dismissed', 'true');
    },
    confirm() {
      this.hide();
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style scoped>
.onboarding-content {
  width: calc(100% - 48px);
  height: 420px;
  padding: 0px 16px;
}

.onboarding-slide {
  padding: 0px 32px;
  height: 360px;
  display: flex;
  flex-direction: column;
}

.onboarding-slide .onboarding-title {
  font-weight: 800;
  font-size: 20px;
}

.onboarding-slide .onboarding-icon img {
  width: 120px;
  height: auto;
  margin: 16px 0px;
}

.onboarding-slide .onboarding-text {
  text-align: left;
  overflow-y: auto;
}

::v-deep(.modal_title) {
  width: 100%;
}

::v-deep(.carousel__prev),
::v-deep(.carousel__next) {
  background-color: var(--color_orange) !important;
}

::v-deep(.modal_close) {
  display: none;
}

.carousel__pagination {
  padding-inline-start: 0px;
}

::v-deep(.carousel__pagination-button) {
  background-color: var(--color_mint) !important;
  opacity: 0.3;
}

::v-deep(.carousel__pagination-button--active) {
  background-color: var(--color_mint) !important;
  opacity: 1.0;
}
</style>
