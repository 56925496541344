<template>
  <div class="side-menu-wrapper">
    <img class="colada-icon" src="../assets/colada_icon.png" />
    <div v-if="user != null" class="name">{{user.firstname}}<br/>{{user.lastname}}</div>
    <div class="options-wrapper">
      <button class="single-option-wrapper" @click="optionSelected('reportConfigs')">
        <div v-bind:class="[selectedTab === 'reportConfigs' ? 'active' : 'inactive']">
          <i class="single-option fas fa-file-alt"></i>
        </div>
      </button>
      <button class="single-option-wrapper" @click="optionSelected('explorer')">
        <div v-bind:class="[selectedTab === 'explorer' ? 'active' : 'inactive']">
          <i class="single-option fas fa-folder"></i>
        </div>
      </button>
      <button class="single-option-wrapper" @click="optionSelected('contactBook')">
        <div v-bind:class="[selectedTab === 'contactBook' ? 'active' : 'inactive']">
          <i class="single-option fas fa-address-book"></i>
        </div>
      </button>
      <button v-if="$store.getters.userIsAdmin" class="single-option-wrapper" @click="optionSelected('settings')">
        <div v-bind:class="[selectedTab === 'settings' ? 'active' : 'inactive']">
          <i class="single-option fas fa-cog"></i>
        </div>
      </button>
      <button class="single-option-wrapper" @click="optionSelected('qrcode')">
        <div v-bind:class="[selectedTab === 'qrcode' ? 'active' : 'inactive']">
          <i class="single-option fas fa-qrcode"></i>
        </div>
      </button>
      <button class="single-option-wrapper" @click="optionSelected('law')">
        <div v-bind:class="[selectedTab === 'law' ? 'active' : 'inactive']">
          <i class="single-option fas fa-balance-scale-left"></i>
        </div>
      </button>

    </div>
    <a class="clickable-button" @click="logoutClicked">
      <i class="logout-button fas fa-sign-out-alt"></i>
    </a>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'SideMenuComponent',
  components: {
  },
  mounted() {
    this.user = this.$store.getters.user;
  },
  data() {
    return {
      user: null,
      selectedTab: 'reportConfigs',
    };
  },
  methods: {
    logoutClicked() {
      this.$emit('logoutCallback');
    },
    optionSelected(newValue) {
      this.selectedTab = newValue;
      this.$router.push(`/${newValue}`);
    },
  },
};
</script>

<style scoped>
.name {
  line-height: 18px;
  font-size: 16px;
  max-height: 40px;
  text-overflow: ellipsis;
}
.side-menu-wrapper {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 140px;
  z-index: 99;
  background-color: white;
}

.colada-icon {
  width: 70%;
  aspect-ratio: 1;
  margin-top: 10px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.single-option-wrapper {
  border-width: 0px;
  background-color: transparent;
}

.single-option-wrapper:hover {
  cursor: pointer;
  background-color: var(--color_mint)33;
}

.options-wrapper {
  width: 100%;
  top: 150px;
  bottom: 100px;
  display: flex;
  justify-content: top;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
}

.single-option {
  width: 44px;
  height: 44px;
  display: block;
  margin: 30px auto;
}

.active .single-option {
  color: var(--color_mint);
}

.inactive .single-option {
  color: var(--color_brown);
}

.clickable-button {
  background-color: var(--color_brown);
  border-radius: 100%;
  width: 50%;
  left: 50%;
  bottom: 22px;
  aspect-ratio: 1;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0);
}

.clickable-button:hover {
  cursor: pointer;
}

.logout-button {
  display: block;
  margin: auto;
  height: 50%;
  color: white;
  width: 60%;
  height: 60%;
  padding: 20%;
}

@media (max-height: 770px) {
  .single-option {
    width: 32px;
    height: 32px;
    margin: 15px auto;
  }

  .clickable-button {
    width: 30%;
  }

  .colada-icon {
    width: 50%;
    margin-top: 22px;
  }

  .name {
    line-height: 14px;
    font-size: 12px;
    max-height: 26px;
  }

  .options-wrapper {
    bottom: 70px;
  }
}

@media screen and (max-width: 800px) {
  .side-menu-wrapper {
    width: 80px
  }
  .clickable-button {
    width: 44px;
    height: 44px;
  }
}

</style>
