<template>
  <div class="legal">
    <div class="p intro">{{terms.intro}}</div>
    <div class="p paragraph" v-for="(p, idx) in terms.paragraphs" :key="idx">
      <div class="p paragraph_title">§{{(idx + 1)}} {{p.title}}</div>
        <div class="p paragraph_section" v-for="(s, sIdx) in p.sections" :key="sIdx">
          <div class="p paragraph_section_nr">({{(sIdx + 1)}})</div>
          <div class="p paragraph_section_content"><span v-html="s.text"/>
            <div class="p paragraph_subsection" v-for="(sub, subIdx) in s.subSections" :key="subIdx">
              <div class="p paragraph_section_title">({{letterIndex(subIdx)}}) {{sub}}</div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */
import TermsArray from '@/helpers/terms';

export default {
  name: 'TermsOfUsageComponent',
  components: {
  },
  data() {
    return {
      terms: TermsArray,
    };
  },
  mounted() {
  },
  methods: {
    letterIndex(idx) {
      return String.fromCharCode(97 + idx);
    },
  },
};
</script>

<style scoped>
  @import url('../styles/legal.css');
</style>
