module.exports = {
  keyShort: 'en',
  key: 'en-GB',
  general: {
    back: 'Back',
    root: 'Root',
    pleaseChoose: 'Please choose',
    ok: 'OK',
    cancel: 'Cancel',
    copy: 'copy',
    empty: 'No entries found!',
    uploading: 'Uploading...',
    refresh: 'Refresh',
    inactive: 'Inactive',
    templates: 'Templates',
    month: 'Month',
    year: 'Year',
    orderNow: 'Order now!',
    row: 'Row',
  },
  login: {
    btnLogIn: 'Log in',
    onboarding: 'Onboarding',
    createFree: 'New tenant...',
    terms: 'Terms of Usage',
    privacy: 'Privacy Disclaimer',
    imprint: 'Imprint',
    startForFree: 'Start for free!',
    showLogin: 'To Log in',
    introTitle: 'Welcome to Collada!',
    introText: 'Are you new to Collada? Then create a new tenant for free right now!',
    introText2: 'Or do you already have a tenant? Just open the log in and start collecting data!',
  },
  settings: {
    daymessages: 'Announcements',
    users: 'Users',
    usergroups: 'User groups',
    devices: 'Devices',
    references: 'References',
    invoicing: 'Subscription',
    mailsettings: 'Mail server',
  },
  qrcode: {
    download: 'Download QR Code as image',
    copy: 'Copy connection information to clip board',
    lblTenant: 'Your tenant-ID:',
    lblConnInfo: 'Your connection information',
    lblHint: 'Important:',
    lblStoreSafelyHint: 'Make sure to store your connection information and / or the QR code safely! '
    + 'Your private key to encrypt and decrypt all your data is included in this information! If you '
    + 'loose this information, you can not access your data anymore! And we (the Collada-Team) can not '
    + 'recover this information for you! It is NOT stored anywhere in our systems!',
    btnCreateMagicLink: 'Create own Magic Link',
  },
  pageTitle: {
    dayMessages: 'Announcements',
    forms: 'Forms',
    login: 'Login',
    reports: 'Reports',
    explorer: 'Files',
    settings: 'Settings',
    users: 'Users',
    usergroups: 'User groups',
    reportDetail: 'Report detail',
    devices: 'Devices',
    contactBook: 'Contacts',
    references: 'References',
    qrcode: 'QR Code',
    legal: 'Legal',
    invoicing: 'Subscription',
  },
  dayShortNames: {
    mo: 'Mo',
    tu: 'Tu',
    we: 'We',
    th: 'Th',
    fr: 'Fr',
    sa: 'Sa',
    su: 'Su',
  },
  modal: {
    titleEditFolder: 'Edit folder',
    titleCreateFolder: 'Create folder',
    labelName: 'Name',
    labelInherits: 'Inherits rights',
    labelRights: 'Rights',
    labelUserGroup: 'User group',
    labelRead: 'Read',
    labelWrite: 'Write',
    labelCollectData: 'Collect data',
    labelEditConfig: 'Edit config',
    labelAddUserGroup: 'Add user group',
    titleEditForm: 'Edit form',
    titleCreateForm: 'Create form',
    labelUserOrGroup: 'User / Group',
    labelFirstname: 'Given name',
    labelLastname: 'Last name',
    labelUsername: 'User name',
    labelEmail: 'E-Mail',
    labelEmailNew: 'New E-Mail-Address',
    labelPassword: 'Password',
    labelPasswordConfirmation: 'Confirm password',
    labelCaptcha: 'Enter code',
    lblAcceptTerms: '',
    txtAcceptTerms: 'I hereby conform to have read an accepted the <a href="/legal/privacy" target="_legal">Privacy Disclaimer</a> '
      + 'und <a href="/legal/terms" target="_legal">Terms of use</a>.',
    labelIsAdmin: 'Is Admin',
    labelProductPermissions: 'Permissions',
    labelUsergroups: 'User groups',
    labelUsers: 'Users',
    labelUserName: 'User name',
    titleEditUserGroup: 'Edit user group',
    titleCreateUserGroup: 'Create user group',
    labelGroupName: 'Group name...',
    titleEditUser: 'Edit user',
    titleCreateUser: 'Create user',
    labelExportCVS: 'Export CSV',
    labelStartDate: 'Start date',
    labelEndDate: 'End date',
    labelFieldSeparator: 'Field separator',
    labelLineSeperator: 'Line break',
    hintLineSeperator: '<NEWLINE> for Enter / Return',
    labelFieldValueStartEnd: 'Value start / end marker',
    labelInCellListSeparator: 'List separator within a cell',
    hintInCellListSeparator: '<SPACE> for sapce',
    labelOptions: 'Options',
    titleDelete: 'Confirm deletion',
    textDelete: 'Do you really want to delete this item?',
    textDeactivate: 'Do you really want to deactivate this item? Users won\'t be able to download it and collect data any more. The form will be listed as inactive and can be reactivated. Continue?',
    textDeleteConfig: 'Do you really want to delete this form? All assigned reports will also be removed from the backend and the end user\'s devices. Continue?',
    titleDeactivate: 'Confirm deactivate',
    titleRenameFile: 'Rename file',
    textRenameFile: 'Please enter the new name of the file:',
    titleUserGroupName: 'User group',
    textUserGroupName: 'Please enter the name of the user group:',
    titlePageName: 'Page name',
    textPageName: 'Please enter the name of the page:',
    titleDropDownValueName: 'Drop down value',
    textDropDownValueName: 'Please enter the caption for the drop down value:',
    titleReportConfigName: 'Form name',
    textReportConfigName: 'Please enter the new name of the form:',
    titleEditContactGroup: 'Edit contact group',
    titleCreateContactGroup: 'Create contact group',
    titleEditContact: 'Edit contact',
    titleCreateContact: 'Create contact',
    titleContact: 'Contact',
    labelPhone: 'Phone',
    labelMobile: 'Mobile',
    labelFax: 'Fax',
    labelStreet: 'Street',
    labelStreet_2: 'Street (additional)',
    labelZipCode: 'Zipcode',
    labelCity: 'City',
    labelDepartment: 'Department',
    labelPosition: 'Position',
    labelTitle: 'Title',
    labelRoom: 'Room',
    labelCompany: 'Company',
    labelCountry: 'Country',
    titleEditDayMessage: 'Edit announcement',
    titleCreateDayMessage: 'Create announcement',
    labelDescription: 'Description',
    labelShouldInvalidates: 'should expire',
    labelInvalidateDateTime: 'Expiration date',
    labelShowOnWeb: 'Show in browser',
    labelShowOnColada: 'Show in Collada app',
    labelDayMessage: 'Announcement',
    titleNewTenant: 'New tenant',
    textNewTenant: 'Please enter the new tenant\'s name',
    titleAdminPassword: 'Password for administrator',
    textAdminPassword: 'Please enter the admin user\'s password (user name: admin)',
    titleMagicLink: 'Magic link',
    textMagicLink: 'Please enter the password for this magic link',
    exportLanguage: 'Export language',
    exportFilter: 'Export filter',
    textTemplateDesc: 'Please enter a description for this template',
    titleTemplateDesc: 'New template',
    titleFreeTenant: 'Create free tenant',
    labelAdminPassword: 'Administrator\'s Password',
    labelTenantName: 'Tenant name',
    labelAdminName: 'Username of admin',
    titleEditReference: 'Edit reference',
    titleCreateReference: 'Create reference',
    labelContact: 'Contact',
    titleDeleteTenant: 'Delete tenant',
    textDeleteTenant: 'Do you really want to delete the whole tenant including all data? This operation can NOT be reverted. All data will be wiped from the server!',
    textConfirmTenantNameForDeletion: 'Please enter the tenant id <b>§1</b> to confirm its deletion.',
    titleMagicLinkPassword: 'Password for magic link',
    textMagicLinkPassword: 'Please enter a password to protect this link or confirm (and remember) the generated one.',
    taxId: 'Tax Id',
    titleLeaveEditor: 'Leave Designer',
    textLeaveEditor: 'Did you save all changes? Unsaved changes will be lost when leaving the designer.',
    titleConfirmExecution: 'Aktionen durchführen',
    textConfirmExecution: 'Wollen Sie die folgenden Aktionen jetzt durchführen?',
    titleArisModelPath: 'Import ARIS model',
    textArisModelPath: 'Please enter the path of the model in the ARIS data base:',
    columnEdit: {
      titleEditColumn: 'Edit column',
      titleCreateColumn: 'Add column',
      labelColumnInputType: 'Keyboard type',
      labelColumnType: 'Type',
      labelColumnName: 'Column name',
      labelColumnWidth: 'Column width in %',
      labelColumnDropDownValues: 'Values',
    },
  },
  message: {
    loading: 'Loading...',
    saving: 'Saving...',
    missingConnectionInfo: 'Missing or wrong connection information!',
    loginFailed: 'Error during login! Please check your credentials!',
    newName: 'New name:',
    confirmDelete: 'Continue with deletion?',
    noAccessRights: 'You don\'t have the necessary access rights!',
  },
  action: {
    designer: 'Designer',
    accessRights: 'Access rights...',
    rights: 'Rights...',
    delete: 'Delete',
    cancel: 'Cancel',
    openInTab: 'Open in tab',
    download: 'Download',
    edit: 'Edit',
    remove: 'Remove',
    copy: 'Copy',
    reactivate: 'Reactivate',
    deactivate: 'Deactivate',
    search: 'Search...',
    sort: 'Sort',
    deleteTenant: 'Delete whole tenant',
    workflow: 'Workflow',
  },
  pagination: {
    pageSize: 'per page',
    pageOf: 'of',
    paginationTo: 'to',
    paginationAnd: 'and',
    paginationOf: 'of',
    element: 'element',
    elements: 'elements',
  },
  sorting: {
    all: 'all',
    createdAsc: 'created ▲',
    createdDesc: 'created ▼',
    modifiedAsc: 'modified ▲',
    modifiedDesc: 'modified ▼',
    state0: 'new',
    state1: 'in progress',
    state2: 'done',
    complete: 'complete',
    incomplete: 'incomplete',
  },
  csv: {
    reportName: 'Report name',
    lastModified: 'last modified',
    state: 'State',
    photos: 'Photos',
    voices: 'Voicerecordings',
  },
  charts: {
    state: 'State',
    reportField: 'Field',
    chartType: 'Type',
    from: 'From',
    to: 'to',
    type_bar: 'Bar',
    type_pie: 'Pie',
    type_doughnut: 'Donut',
    cb_true: 'Yes',
    cb_false: 'No',
    cb_none: 'None',
  },
  placeholder: {
    email: 'E-Mail',
    password: 'Password',
    connectionInfo: 'Connection info',
    time: 'Time',
    date: 'Date',
    username: 'User name',
  },
  detail: {
    general: 'General',
    name: 'Name',
    created: 'created at',
    creator: 'created by',
    modified: 'modified at',
    modifier: 'modified by',
    photos: 'Photos',
    recordings: 'Voice recordings',
    recording: 'Recording',
    page: 'Page',
    values: 'Values',
    longitude: 'Longitude',
    latitude: 'Latitude',
    comments: 'Comments',
    placeholderComment: 'Add new comment...',
    saveComment: 'comment',
    alteredContinuation: 'modified the report',
    createdContinuation: 'created the report',
    commentModified: 'modified',
    commentDeleted: 'deleted',
    stateChangeBefore: 'changed state of report from',
    stateChangeTo: 'to',
    stateChangeAfter: '',
    report: 'report',
    reports: 'reports',
    commentAmount: 'comment',
    commentsAmount: 'comments',
    state0: 'new',
    state1: 'in progress',
    state2: 'done',
    selfDelete: 'comment deleted',
    otherDelete: 'comment deleted by',
    editComment: 'Save',
  },
  editor: {
    undo: 'Undo',
    redo: 'Redo',
    save: 'Save',
    tablet: 'Tablet',
    phone: 'Phone',
    language: 'Display Language',
    fallbackLanguage: 'Standard',
    unnamedForm: 'Unnamed form',
    hintUseDragAndDrop: 'Please drag and drop an image file to add it to the form!',
    page: 'Page',
    placeholderEnterText: 'Enter text...',
    placeholderPleaseChoose: 'Please choose...',
    placeholderTapHere: 'Tap here...',
    inspector: {
      general: 'General',
      phoneOnly: 'Smartphone form only',
      name: 'Name',
      view: 'View',
      gridSize: 'Grid size',
      grid: 'Grid',
      auxLines: 'Aux lines',
      allPages: 'All pages',
      inspector: 'Inspector',
      type: 'Type',
      title: 'Title',
      titles: 'Titles',
      titleFallback: 'Standard title',
      languages: 'Languages',
      fallbackLanguage: 'Standard',
      isTitleField: 'Is title Field',
      isMandatory: 'Is manatory',
      function: 'Function',
      position: 'Position / Size',
      posX: 'X-Position',
      posY: 'Y-Position',
      width: 'Width',
      height: 'Height',
      titleWidth: 'Title width',
      dataBinding: 'Data binding',
      displayCondition: 'Conditional display',
      field: 'Dependent on field',
      hasToBe: 'The value must be',
      font: 'Font',
      fontSize: 'Font size',
      fontColor: 'Font color',
      value: 'Value',
      values: 'Values',
      checkboxGroup: 'Checkbox group',
      checkboxColor: 'Color in chart (e.g. #123456)',
      date: 'Date',
      time: 'Time',
      repetition: 'Repetion options',
      days: 'Days',
      datePlaceholder: 'Date (Placeholder)',
      timePlaceholder: 'Time (Placeholder)',
      restrictDevice: 'Only show on certain device type',
      allDevices: 'all device types',
      onlyLarge: 'only tablets',
      onlySmall: 'only phones',
      hideInput: 'hide input',
      numberInput: 'numbers only',
      textInput: 'characters and numbers',
      keyboardType: 'Tastatur-Typ',
      action: 'Action',
      columns: 'Columns',
      hints: {
        name: 'The name of the form.',
        languages: 'Define, in which languages this form is offered to the user when filling data in within the app.',
        fallback: 'This language will be used as fallback, in case no specific title is defined for the currently chosen form language.',
        phoneOnly: 'If on, this setting will lead to not having a special form for tablet-pcs. On tablet-pcs, the phone version of the form will be used.',
        gridSize: 'Distance of the grind points in pixels.',
        grid: 'Use grid (show and snap)',
        auxLines: 'Use auxiliary lines (show and snap)',
        type: 'The type of the chosen field.',
        title: 'The caption to be shown in the form, possibly in multiple languages.',
        isTitleField: 'From all fields marked as title field, a name for collected reports is generated (e.g. First name, last name, company).',
        isMandatory: 'Is this field mandatory? The user is not forced to enter a value and the report is still sent to the portal even with empty mandatory fields, but reports missing values in mandatory fields are marked in the overview list.',
        page: 'Defines whether this field is to be shown an all pages of the form or only on a specific page.',
        posX: 'Distance from left border of the form in pixels',
        posY: 'Distance from top border of the form in pixels',
        width: 'Width in pixels',
        height: 'Height in Pixels',
        titleWidth: 'Relative width of the title in relation to whole width.',
        field: 'Is this field always visible or only if a specific value in another field (drop down list or checkbox) is chosen?',
        hasToBe: 'If this field is dependent on another field, which value must that field have?',
        restrictDevice: 'Should this field be visibile on all devices or on phones or tablets only?',
        font: 'Font type of title',
        fontSize: 'Font size of title',
        fontColor: 'Font color of title',
        valueFont: 'Font type of value',
        valueFontSize: 'Font size type of value',
        valueFontColor: 'Font color type of value',
        keyboardType: 'Which keyboard type hould be shown for entering data? Characters and numbers or numbers only?',
        hideInput: 'Sensitive content as password can be hidden during input.',
        action: 'Define the type of action that will be triggered when this field is tapped.',
        values: 'Define the list of options to be shown in this drop down field. New Options can be added, existing ones can be removed and the order of the options can be altered.',
        checkboxGroup: 'Checkbox fields can be grouped. Within a group, only one checkbox can be checked at a time. Grouped checkbox fields can be visualized in charts.',
        checkboxColor: 'Color of this group in the chart, defined in HEX format (e.g. #000000 for Black)',
        date: 'Should it be possible to enter a date?',
        time: 'Should it be possible to enter a time?',
        repetition: 'Should the options for reoccurence be shown?',
        days: 'Should the list of weekdays be shown?',
        datePlaceholder: 'Placeholder to be shown as long as no date has been chosen.',
        timePlaceholder: 'Placeholder to be shown as long as no time has been chosen.',
        columns: 'Define the table columns. Columns can be added, removed, re-ordered and edited.',
      },
      placeholder: {
        newValue: 'New value',
        newColumn: 'Column name',
      },
    },
    types: {
      label: 'Label',
      textfield: 'Text field',
      textarea: 'Text area',
      checkbox: 'Checkbox',
      dropdown: 'Drop down',
      image: 'Image',
      action: 'Action',
      signature: 'Signature',
      background: 'Background',
      date: 'Date',
      reference: 'Reference',
      list: 'List',
      table: 'Table',
      hints: {
        label: 'A single-line, static text. Font tyoe, size and color can be defined in the inspector.',
        textfield: 'A simple, single-lined text input field. Font tyoe, size and color can be defined in the inspector.',
        textarea: 'A multi-line text input area. Font tyoe, size and color can be defined in the inspector.',
        checkbox: 'A check box field, can be grouped. If grouped, only one box in the group can be checked.',
        dropdown: 'A drop down list with arbitrary values. Single-selection only.',
        image: 'A static image to be shown. Has to be added to the form using drag & drop.',
        action: 'An action field. The action to be performed when tapped is defined in the inspector.',
        signature: 'Captures signatures or drawings done with the finger or a pen / stylus.',
        background: 'A simple geometric shape, e.g. for background design or visual grouping. Shape and color are defined in the inspector.',
        date: 'A felxible field to enter dates or times. Supports date and time or only of them as well as reoccuring appointments by interval and weekdays.',
        reference: 'Field to define a reference for the report. References group data to a certain structure, e.g. a project or a customer.',
        list: 'Field to define a simple dymanic list. Rows can be added, removed and edited.',
        table: 'Field to define a complex dymanic table. You can customize the columns in the inspector. Rows can be added, removed and edited.',
      },
    },
    actions: {
      recordVoice: 'Record voice',
      scanQRCode: 'Scan QR code',
      assignPhotos: 'Assign photos',
      takePhoto: 'Take photo',
      remoteProcessing: 'Remote Processing',
      locatePosition: 'Locate position',
      loadList: 'Load list',
    },
    repetitionOptions: {
      once: 'einmalig',
      daily: 'täglich',
      weekly: 'wöchentlich',
      every14days: '14-tägig',
      monthly: 'monatlich',
      every4weeks: 'alle 4 Wochen',
      quarterly: 'quartalsweise',
      semiYearly: 'halbjährlich',
      yearly: 'jährlich',
    },
    shapes: {
      rectangle: 'Rectangle',
      ellipsis: 'Ellipsis',
      line: 'Line',
    },
    toolbar: {
      addField: 'Add new field',
      duplicate: 'Duplicate',
      delete: 'Delete',
      addVertical: 'Add vertical',
      addHorizontal: 'Add horizontal',
      front: 'Front',
      back: 'Back',
      cloneTemplate: 'New config bases on template',
    },
  },
  error: {
    'code_-1': 'An error occured! Please try again!',
    code_901: 'The limit of concurrently registered devices is reached. Please contact your administrator.',
    code_902: 'You have no permission to log in. Please contact your administrator.',
    code_903: 'Your subscription has expired! You can not upload any data or report anymore.',
    code_907: 'Your tenant has exceeded the space limit.',
    code_909: 'The code does not match.',
    code_920: 'You can not delete the last admin user!',
    upload: 'The upload of at least on file failed!',
    no_upload_to_root: 'You can only upload files to folders, but not to the root.',
    wrongTenantName: 'The tenant id you entered does not match your tenant\'s id. Please try again.',
    requestingNewEmailFailed: 'Requesting a new E-Mail address failed!',
    general: 'An error occured! Please try again!',
  },
  validationError: {
    firstNameEmpty: 'Please enter a first name',
    lastNameEmpty: 'Please enter a lasts name',
    emailEmpty: 'Please enter an E-Mail address',
    cityEmpty: 'Please enter a city',
    streetEmpty: 'Please enter a street and house number',
    zipCodeEmpty: 'Please enter a zip code',
    usernameNotAvailable: 'This username is already in use. Please use a different one.',
    emailNotAvailable: 'This E-Mail address is already in use. Please use a different one.',
    errorEmptyOrInsufficientPassword: 'Please enter a password. Rules: Min. 8 characters, at least a lower and an upper case character, a number and a special character.',
    errorPasswordsDontMatch: 'The passwords do not match',
    errorTermsNotAccepted: 'You must accept our terms and conditions in order to use the Collada platform!',
    errorCaptcha: 'Please type the shown code in the input field.',
    invalidEmail: 'Please enter a valid E-Mail address',
    errorTenantNameEmpty: 'Please enter a tenant name (min. 5 characters).',
    errorAdminNameTooShort: 'Please enter a username for the admin user (min. 3 characters).',
    nameEmpty: 'Please enter a name',
    usernameTooShort: 'Please enter a username (min. 5 characters).',
    columnNameEmpty: 'Please enter a name',
  },
  invoicing: {
    titleData: 'Invoice information',
    taxId: 'Tax ID',
    btnSendNewLink: 'Send new confirmation link',
    btnAddAccoutHolder: 'Add Accounting Information',
    titleLastInvoice: 'Last invoice',
    tenant: 'Tenant',
    subscriptionType: 'Subscription Type',
    subscriptionPeriodStart: 'Period Start Date',
    subscriptionPeriodEnd: 'Period End Date',
    running: 'running',
    cancelled: 'cancelled',
    date: 'Date',
    nr: 'Nr',
    period: 'Period',
    product: 'Product',
    amount: 'Amount',
    status: 'Status',
    transactionId: 'Transaction ID',
    cancelSubscription: 'End subscription',
    edit: 'Edit...',
    showPDF: 'Show PDF',
    reactivate: 'Reactivate',
    titleCancelSubscription: 'End subscription',
    textCancelSubscription: 'Do you really want to end your subscription? You will still have access to the portal and your data, '
      + 'but you will not be able to collect new data with the Collada-App. Continue?',
    infoMailSent: 'An E-Mail with an confirmation link has been sent to the address. Please confirm your E-Mail-address by opening the link.',
    modalTitleAddAccountHolder: 'Add invoicing data',
    modalTitleEditAccountHolder: 'Edit invoicing data',
    btnRequestEmailUpdate: 'Send request',
    btnCancelEmailUpdate: 'Cancel',
    email_unconfirmed_hint: 'Please confirm your E-Mail-address  by opening the link provided in the confirmation mail sent to you. '
      + 'Afterwards, you can select your subcription. In case you did not receive a cofirmation mail, please check your spam folders or request a new mail using the button below.',
    complete_accountholder: 'Please complete your invoicing data and confirm your E-Mail-address afterwards. You can then select your subscription.',
    endOfCurrentPeriod: 'End of current invoicing period',
    nextProductId: 'Booked subscription starting with next period',
    history: 'Invoice history',
    subscriptions: 'Subscriptions',
    btnSubscribe: 'Subscribe',
    titleConfirmDialog: 'Order Confirmation',
    textConfirmDialog: 'By confirming this dialog you order legally binding and payable the following subscription for the Collada solution:',
    hintEffectiveImmediately: 'The subscription with the options listed will be activated immediately after your payment is received.',
    hintEffectiveNextPeriod: 'The subscription with the options listed will become effective after the current invoicing period.',
    externalPaymentProviderHint: 'At the beginning of each invoicing perios, an invoice will be sent to the E-Mail address you entered. '
      + 'Please wire transfer the money within 14 days after receiving the invoice following the instructions stated in the invoice.',
    invoice_status_OPEN: 'open',
    invoice_status_PAID: 'paid',
    hint_net_prices: 'Prices are net prices, plus 19% VAT!',
  },
  workflow: {
    save: 'Save',
    load: 'Load',
    execute: 'Execute',
    node: {
      FieldChooser: 'Field',
      GetFieldValue: 'Get Value',
      GetFieldValueCustom: 'Get Value',
      SetFieldValue: 'Set Value',
      SetFieldValueCustom: 'Set Value',
      GetCreationDate: 'Created at',
      GetModificationDate: 'Changed at',
      GetStatus: 'Get State',
      SetStatus: 'Set State',
      StatusConstant: 'State',
      SendMail: 'Send Mail',
    },
    nodeChooser: {
      FieldChooser: 'Choose Field',
      GetFieldValue: 'Get Value',
      GetFieldValueCustom: 'Get Value (custom field)',
      SetFieldValue: 'Set Value',
      SetFieldValueCustom: 'Set Value (custom field)',
      GetCreationDate: 'Created at',
      GetModificationDate: 'Changed at',
      GetStatus: 'Get State',
      SetStatus: 'Set State',
      StatusConstant: 'Choose State',
      SendMail: 'Send Mail',
    },
  },
  mailsettings: {
    title: 'Mail server settings',
    labelHost: 'Server',
    labelPort: 'Port',
    labelUsername: 'Username',
    labelPassword: 'Password',
    labelFromAddress: 'Sender',
    securityHint: 'Your mail credentials are stored encrypted with our tenant key. Neither the Collada team nor besides your admins can see those settings.',
    error: {
      fromAddress: 'Please enter a sender name',
      username: 'Please enter an username',
      port: 'Please enter a port number',
      host: 'Please enter s host name',
      password: 'Please enter a password',
    },
  },
  legal: {
    imprint: 'Imprint',
    terms: 'Terms of use',
    privacy: 'Privacy disclaimer',
    licenses: 'Licenses',
  },
};
