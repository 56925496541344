import { createRouter, createWebHashHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/reportConfigs',
    name: 'ReportConfigs',
    component: () => import(/* webpackChunkName: "reportConfigs" */ '../views/ReportConfigListView.vue'),
  },
  {
    path: '/reports/:configId/:configName',
    name: 'Reports',
    props: true,
    component: () => import(/* webpackChunkName: "reports" */ '../views/ReportListView.vue'),
  },
  {
    path: '/reportDetail',
    name: 'ReportDetail',
    component: () => import(/* webpackChunkName: "reportDetail" */ '../views/ReportDetailView.vue'),
  },
  {
    path: '/edit/:configId',
    name: 'Editor',
    props: true,
    component: () => import(/* webpackChunkName: "edit" */ '../views/EditorView.vue'),
  },
  {
    path: '/explorer',
    name: 'Explorer',
    component: () => import(/* webpackChunkName: "explorer" */ '../views/ExplorerView.vue'),
  },
  {
    path: '/contactBook',
    name: 'ContactBook',
    component: () => import(/* webpackChunkName: "contactBook" */ '../views/ContactBookView.vue'),
  },
  {
    path: '/usergroups',
    name: 'UserGroups',
    component: () => import(/* webpackChunkName: "usergroups" */ '../views/UserGroupsView.vue'),
  },
  {
    path: '/users',
    name: 'User',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
  },
  {
    path: '/qrcode',
    name: 'QRCode',
    component: () => import(/* webpackChunkName: "qrcode" */ '../views/QRCodeView.vue'),
  },
  {
    path: '/loadAttachment/:attachmentId',
    name: 'LoadAttachment',
    props: true,
    component: () => import(/* webpackChunkName: "loadAttachment" */ '../views/LoadAttachmentView.vue'),
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "devices" */ '../views/DevicesView.vue'),
  },
  {
    path: '/daymessages',
    name: 'DayMessages',
    component: () => import(/* webpackChunkName: "daymessages" */ '../views/DayMessagesView.vue'),
  },
  {
    path: '/references',
    name: 'References',
    component: () => import(/* webpackChunkName: "references" */ '../views/ReferencesView.vue'),
  },
  {
    path: '/law',
    name: 'Law',
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalView.vue'),
  },
  {
    path: '/invoicing/:transactionId?',
    name: 'Invoicing',
    props: true,
    component: () => import(/* webpackChunkName: "legal" */ '../views/InvoicingView.vue'),
  },
  {
    path: '/workflow/:configId',
    name: 'Workflow',
    props: true,
    component: () => import(/* webpackChunkName: "legal" */ '../views/WorkflowEngineView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
