<template>
  <CustomModal v-model="showModal" @confirm="confirm" @cancel="cancel" :show-cancel="''">
    <template v-slot:title>{{ $t('modal.labelDayMessage') }}</template>
    <div class="wrapper" v-for="dayMessage in dayMessages" :key="dayMessage._id">
      <div class="title">{{dayMessage.title}}</div>
      <div class="description">{{dayMessage.description}}</div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';

export default {
  name: 'DayMessageModal',
  props: ['dayMessagesToShow'],
  components: {
    CustomModal,
  },
  data() {
    return {
      dayMessages: [],
      showModal: false,
      resolve: null,
      reject: null,
      promise: null,
    };
  },
  watch: {
    dayMessagesToShow(newVal) {
      this.dayMessages = newVal;
    },
  },
  methods: {
    async show() {
      this.showModal = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    hide() {
      this.showModal = false;
      this.$emit('done');
    },
    confirm() {
      this.resolve(true);
      this.hide();
    },
    cancel() {
      this.resolve(false);
      this.hide();
    },
  },
};
</script>

<style scoped>

.wrapper {
  margin: 0px 0px 3px 0px;
}
.title {
  font-weight: bold;
}
.description {
  margin: 0px 0px 0px 3px;
}

</style>
