<template>
  <div class="legal">
    <div class="p paragraph" v-for="(p, idx) in privacy.paragraphs" :key="idx">
      <div class="p paragraph_title">{{(idx + 1)}}. {{p.title}}</div>
        <div class="p paragraph_section" v-for="(s, sIdx) in p.sections" :key="sIdx">
        <div class="p paragraph_section_content" v-html="s"/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */
import PrivacyArray from '@/helpers/privacy';

export default {
  name: 'PrivacyComponent',
  components: {
  },
  data() {
    return {
      privacy: PrivacyArray,
    };
  },
  mounted() {
  },
  methods: {
    letterIndex(idx) {
      return String.fromCharCode(97 + idx);
    },
  },
};
</script>

<style scoped>
  @import url('../styles/legal.css');
</style>
