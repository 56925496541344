import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    pageSize: -1,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setTenant(state, tenant) {
      state.tenant = tenant;
    },
    setPageSize(state, pageSize) {
      state.pageSize = pageSize;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    user: (state) => state.user,
    userIsAdmin: (state) => state.user != null && state.user.isAdmin,
    tenant: (state) => state.tenant,
    currentPageSize: (state) => state.pageSize,
  },
});
