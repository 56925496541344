<template>
  <SideMenuComponent v-if="loggedIn && user != null" @logoutCallback="performLogout" />
  <LoginView v-if="!loggedIn" @loggedInCallback="loggedInCallback" @dayMessagesCallback="dayMessagesCallback"/>
  <DayMessageModal ref="dayMessageModal" :dayMessagesToShow="dayMessagesToShow"/>
  <router-view class="routerStyle" v-if="loggedIn" ref="routerView"/>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */
import SideMenuComponent from '@/components/SideMenuComponent.vue';
import LoginView from '@/views/LoginView.vue';
import DayMessageModal from '@/components/modals/DayMessageModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';

export default {
  name: 'App',
  components: { SideMenuComponent, LoginView, DayMessageModal },
  data() {
    return {
      loggedIn: false,
      routerHeight: '200px',
      user: null,
      dayMessagesToShow: null,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    if (document.location.href.indexOf('imprint') > -1) {
      this.$router.replace('/imprint');
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  watch: {
    $route: {
      handler(/* route */) {
        // console.log(route, route.meta, this.loggedIn);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isPublicRoute() {
      const res = this.$route.meta.isPublicRoute || this.$route.path === '/';
      console.log('isPublicRoute', res);
      return res;
    },
    resizeHandler() {
      this.routerHeight = `${(window.innerHeight)}px`;
    },
    async loggedInCallback(user, tenant) {
      console.log('APP: loggedInCallback');
      this.loggedIn = true;
      this.$store.commit('setUser', user);
      this.$store.commit('setTenant', tenant);
      this.user = user;

      console.log('LOGGED IN!', this.loggedIn);

      const getVars = ServiceHelper.getVarsFromURI();

      if (localStorage.getItem('tId') != null) {
        this.$router.replace(`/invoicing/${localStorage.getItem('tId')}`);
        localStorage.removeItem('tId');
      } else if (getVars.attachmentId != null) {
        this.$router.replace(`/loadAttachment/${getVars.attachmentId}`);
      } else {
        this.$router.replace('ReportConfigs');
      }
      // this.$router.replace('Explorer');
    },
    async dayMessagesCallback(dayMessages) {
      this.dayMessagesToShow = dayMessages;
      if (dayMessages != null && dayMessages.length !== 0) {
        this.$refs.dayMessageModal.show();
      }
    },
    performLogout() {
      localStorage.removeItem('col_log_in');
      localStorage.removeItem('col_user');
      localStorage.removeItem('col_tenant');
      window.location.href = '/';
    },
  },
};
</script>

<style>
@import url('./styles/app.css');
</style>
