import { createApp } from 'vue';
// eslint-disable-next-line no-unused-vars
import { vfmPlugin } from 'vue-final-modal';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { createI18n } from 'vue-i18n';
import Popper from 'vue3-popper';

import App from './App.vue';
import AppEmailConfirmed from './AppEmailConfirmed.vue';
import AppPublicPages from './AppPublicPages.vue';
import router from './router';
import store from './store';
import { LocaleDE, LocaleEN, getBrowserLocale } from './helpers/LanguageHelper';
import ServiceHelper from './helpers/ServiceHelper';

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const locale = getBrowserLocale();

const getVars = ServiceHelper.getVarsFromURI();

if (getVars.transId != null) {
  localStorage.setItem('tId', getVars.transId);
  setTimeout(() => {
    document.location.href = '/';
  }, 100);
} else if (document.location.href.indexOf('emailConfirmationSuccessful') > 0 || document.location.href.indexOf('emailConfirmationFailed') > 0) {
  const app = createApp(AppEmailConfirmed)
    .use(router)
    .use(createI18n({
      locale: locale != null ? getBrowserLocale() : 'en',
      fallbackLocale: 'en',
      messages: {
        en: LocaleEN,
        de: LocaleDE,
      },
    }))
    .use(vfmPlugin({
      key: '$vfm',
      componentName: 'VueFinalModal',
      dynamicContainerName: 'ModalsContainer',
    }));
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.mount('#app');
} else if (document.location.href.indexOf('legal') > 0) {
  const app = createApp(AppPublicPages)
    .use(router)
    .use(createI18n({
      locale: locale != null ? getBrowserLocale() : 'en',
      fallbackLocale: 'en',
      messages: {
        en: LocaleEN,
        de: LocaleDE,
      },
    }))
    .use(vfmPlugin({
      key: '$vfm',
      componentName: 'VueFinalModal',
      dynamicContainerName: 'ModalsContainer',
    }));
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.mount('#app');
} else {
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(createI18n({
      locale: locale != null ? getBrowserLocale() : 'en',
      fallbackLocale: 'en',
      messages: {
        en: LocaleEN,
        de: LocaleDE,
      },
    }))
    .use(vfmPlugin({
      key: '$vfm',
      componentName: 'VueFinalModal',
      dynamicContainerName: 'ModalsContainer',
    }));

  // SILENCE warnings for now
  app.config.warnHandler = () => {
    // `trace` is the component hierarchy trace
  };
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('Popper', Popper);
  app.mount('#app');
}
