/* eslint-disable */
module.exports = {
  "intro" : "Diese Nutzungsbedingungen für die Collada Plattform ('Nutzungsbedingungen') regeln das rechtliche Verhältnis zwischen der app affairs GmbH (nachfolgend Anbieter genannt) als Anbieter der Collada Plattform und Geschäftskunden im Sinne des §14 BGB (nachfolgend 'Kunden' genannt).",
  "paragraphs": [
    {
      "title": "Nutzerkonto",
      "sections": [
        { "text": "Die Nutzung der Collada Plattform setzt das Erstellen eines Nutzerkontos voraus. Dabei wird zwischen der kostenlosen Testnutzung für max. 14 Tage und dem zahlungspflichtigen produktiven Einsatz der Plattform unterschieden. Beide Nutzungsvarianten unterliegen dabei den im Folgenden aufgeführten Nutzungsbedingungen und ebenso den Datenschutzbedingungen (siehe https://portal.collada.app/legal/privacy). Durch  die Nutzung der Plattform kommt ein Nutzungsvertrag zwischen dem Betreiber und dem Kunden zustande." },
        { "text": "Zur kostenlosen Nutzung muss keine Angabe personenbezogener Daten erfolgen, lediglich Nutzername und Passwort zum Zugriff auf das Konto über den automatisch pro Kunden angelegten Initialbenutzer müssen vergeben werden. Mit dem Wechsel zum zahlungspflichtigen Produktiveinsatz muss der Kunden einen Ansprechpartner ('Account Holder') zur Erstellung rechtswirksamer Rechnungen angeben. Der hinterlegte Account Holder gilt fortan als Ansprechpartner und Kontaktperson in allen vertraglichen Belangen." },
        { "text": "Zur Regelung von Urlaubsvertretungen ist die Angabe eines Mailverteilers als Kontakt-Email zulässig, die als Account Holder eingetragene Person muss jedoch einer natürlichen Person mit entsprechenden Vertretungsrechten im Namen des Kunden sein." },
        { "text": "Die Nutzung des Portals und damit auch die Erstellung eines Nutzungskontos ist nur Unternehmern bzw. im Auftrag von Unternehmern gem. § 14 BGB zulässig." },
        { "text": "Werden zur Nutzung der Plattform innerhalb des Nutzungskontos des Kunden Benutzer angelegt und verwaltet, muss es sich dabei ausdrücklich um Mitarbeiter des Kunden handeln, mit denen im Rahmen der Anstellung Vereinbarungen hinsichtlich der korrekten und rechtssicheren Verarbeitung von Daten im Sinne der DSGVO existieren. Die Einhaltung rechtlicher Vorgaben hinsichtlich der Datensicherheit obliegt dem Kunden. Der Anbieter der Colla-da Plattform sichert zu, dass vom Kunden erfasste Daten durch technische Maßnahmen jederzeit verschlüsselt und damit sowohl für Dritte als auch den Anbieter selbst nicht les- oder verarbeitbar abgelegt werden. Ausnahme hiervon sind die vom Anbieter zur Rechnungsstellung und Zahlungsabwicklung notwendigen Daten zum 'Account Holder', welche ebenfalls verschlüsselt, aber durch den Anbieter les- und verarbeitbar gespeichert werden." }
      ]
    },


    {
      "title": "Funktionalitäten der Plattform; Verantwortung des Vertragspartners für User",
      "sections": [
        { "text": "Die Collada Plattform stellt Funktionalitäten bereit, um individuelle Formulare und Berichte aus Standard-Eingabeelementen zu erstellen. Darüber hinaus können Dateien sowie Kontaktinformationen zentral hochgeladen und an die Mitarbeiter des Kunden verteilt werden. Alle dargebotenen Funktionalitäten der Plattform können im Rahmen des kostenlosen Testzugangs auf Funktion und Erfüllung der Anforderungen des Kunden geprüft werden. Es besteht über die auf der Webseite beworbenen und im Rahmen der Testphase bereitgestellten Funktionalitäten kein Anspruch auf weitere Funktionen." },
        { "text": "Zum Erfassen von Informationen und Daten unter Verwendung der individuell im Collada-Portal erstellten Formulare ist die Verwendung der Collada-App unabdingbar. Die Collada-App kann kostenlos auf dem mobilen Endgerät (Smartphone oder Tablet) der Mitarbeiter ('App-User') unter Zuhilfenahme des App-Stores des mobilen Endgeräts installiert werden." },
        { "text": "Alle vom Kunden erfassten Daten (hierunter fallen die individuellen Formulare, die per App gesammelten Informationen sowie die zur Verteilung an die Mitarbeiter hochgeladenen Dateien und Kontakte) werden durch einen kunden-individuellen Schlüssel und durch zeitgemäße Verschlüsselungsverfahren verschlüsselt abgelegt und sind so für Dritte und den Anbieter nicht les- oder verarbeitbar. Die hierfür notwendigen Informationen ('Verbindungsinformationen') werden beim Erzeugen des Nutzungskontos im Browser oder Endgerät des Kunden erzeugt und dem Kunden zur Aufbewahrung im Portal angezeigt. Dem Kunden obliegt die Verantwortung, diese zentralen, zum Nutzen des Portals notwendigen Informationen sicher aufzubewahren. Sollte der Kunde nicht mehr über seine Verbindungsinformationen verfügen, besteht keine Möglichkeit mehr, auf die gespeicherten Daten zuzugreifen." },
        { "text": "Zur Nutzung der Plattform gehört neben der genannten Collada-App auch eine serverbasierte Software ('Backend'). Dem Kunden stehen sowohl in der Testphase als auch im produktiven Betrieb eine bestimmte Serverkapazität zur Verfügung. Es können bei Erreichen des Kapazitätslimits keine weiteren Daten mehr erfasst und keine Dateien und Kontakte zur Verteilung mehr hochgeladen werden." },
        { "text": "Im Rahmen der kostenlosen Nutzung kann der Kunde vorab die Kompatibilität des Portals und der App mit seinen Endgeräten prüfen. Dadurch kann vor Beginn der kostenpflichtigen Nutzung durch den Kunden die Lösung hinsichtlich Kompatibilität und Performance geprüft werden. Im Rahmen der kostenpflichtigen Nutzung durch den Kunden identifizierte funktionale oder technische Anforderungen, welche im Rahmen der kostenlosen Nutzung bereits hätten geprüft werden können, stellen daher keine Mängel dar." },
        { "text": "Für den Betrieb der Collada-App auf den mobilen Endgeräten der App-User ist zumindest zeitweilig eine Online-Verbindung unabdingbar, um die erfassten Daten an das Collada-Portal zu übertragen und zentral zur Verfügung gestellte Dateien und Kontakt auf das Endgerät zu übertragen. Das notwendige Datenvolumen ist dabei von Anzahl sowie Art und Zusammenstellung der vom Kunden selbst erstellten Formulare und hochgeladenen Dateien abhängig. Es obliegt dem Kunden, für entsprechend ausgelegte Internetzugänge der App-User zu sorgen bzw. die Verantwortung hierfür an die App-User weiterzugeben. Der Anbieter bietet keine Verträge zu mobilen oder stationären Datenverbindungen an." },
        { "text": "Der Kunde ist verpflichtet, dem Anbieter Mängel der Software unverzüglich zu melden." }
      ]
    },

    {
      "title": "Verantwortlichkeit bezüglich personenbezogener Daten",
      "sections": [
        { "text": "Der Anbieter erhebt lediglich die zur Nutzung der Plattform und zur ordnungsgemäßen Rechnungslegung und Zahlungsabwicklung notwendigen Daten. Siehe hierzu auch die Datenschutzerklärung unter <a href='https://portal.collada.app/legal/privacy'>https://portal.collada.app/legal/privacy</a>." },
        { "text": "Während der kostenlosen Testphase ist dem Kunden ausdrücklich untersagt, personenbezogene oder 'produktive' (also über eine Testnutzung zur Abklärung der Eignung des Collada Portals für die Kundenzwecke hinausgehende) Daten zu erfassen und zu speichern." },
        { "text": "Im Rahmen der kostenpflichtigen Nutzung gem. Abschnitt 'Kostenpflichtige Abonnements' in diesem Dokument kann der Kunde beliebige Inhalte und Daten erfassen, solange diese weder aufgrund Ihrer Art (gesetzeswidrige Inhalte, vgl. <a href='https://www.bundesjustizamt.de/DE/Themen/Buergerdienste/NetzDG/Fragen/5.html'>hier</a>) gegen geltendes Recht verstoßen. Aufgrund der kompletten Ende-zu-Ende-Verschlüsselung aller erfassten Daten (mit Ausnahme der Informationen über den Account Holder) hat der Anbieter keine Möglichkeit, die Daten zu lesen und damit auch nicht die Möglichkeit, die Art oder den Inhalt der Daten zu prüfen. Die gesetzeskonforme Behandlung der Daten obliegt damit sowohl inhaltlich als auch in Bezug auf die Datensicherheit gem. DSGVO dem Kunden. Der Kunde, vertreten durch den im Collada-Portal hinterlegten Account Holder ist damit verantwortlich im Sinne von Art. 4 Nr. 7 DSGVO in Bezug auf die Erhebung, Verarbeitung und Nutzung dieser Daten. Insbesondere obliegt es dem Kunden, eventuell erforderliche Einwilligungen der User oder der vom User beim Befüllen der individuellen Formulare befragten Personen einzuholen." }
      ]
    },
    {
      "title": "Nutzungsrechte; Verbot der Weitergabe",
      "sections": [
        { "text": "Der Anbieter räumt dem Kunden das einfache, nicht übertragbare Recht zur Nutzung der Vertragsleistungen für eigene Geschäftszwecke im Unternehmen des Vertragspartners unter Einhaltung dieser Nutzungsbestimmungen befristet auf die Vertragsdauer ein." },
        { "text": "Es dürfen keine Zugänge an Dritte vermietet, weiterveräußert oder unterlizenziert werden. Als 'Dritte' sind hierbei alle nicht unmittelbar für den Kunden tätige Personen zu verstehen. Eine kostenlose Weitergabe an Dritte zur temporären Nutzung der kundenindividuellen Formulare im Rahmen der Geschäftszwecke des Kunden ist zeitlich begrenzt und im Rahmen von klar definierten Einsatzzwecken (z.B. Umfragen) möglich." },
        { "text": "Bei Verstößen des Kunden gegen §6 kann der Anbieter den Vertrag unmittelbar und fristlos kündigen." }
      ]
    },
    {
      "title": "Kostenlose Testphase",
      "sections": [
        { "text": "Zum Prüfen der Funktionalität, der Prüfung auf Abdeckung der Anforderungen sowie der Kompatibilität der vorhandenen Endgeräte kann der Kunde eine zeitlich begrenzte Testphase in Anspruch nehmen. Insbesondere ist das Erfassen von personenbezogenen Daten in dieser Phase untersagt." },
        { "text": "Während der kostenlosen Testphase ist der produktive Einsatz beim Kunden nicht gestattet. Bei Zuwiderhandlung behält sich der Anbieter das Recht vor, den Vertrag und damit auch die kostenlose Testphase fristlos zu kündigen. Unter den Einsatz zu Testzwecken fallen die in Absatz (1) in diesem Paragraf genannten Prüfungszwecke. Unter einem produktiven Einsatz wird jeder über die in Absatz (1) dieses Paragraphs genannten Prüfungszwecke, jedwede Erfassung personenbezogener Daten sowie jeglicher für den wertschöpfender Einsatzzweck erachtet." },
        { "text": "Während der kostenlosen Testphase besteht kein Anspruch auf permanente Verfügbarkeit der Plattform." },
        { "text": "Die kostenlose Nutzung unterliegt einer gegenüber der zahlungspflichtigen Nutzung verminderten Serverkapazität. Bei Erreichen dieser Kapazitätsgrenze können keine weiteren Daten mehr erfasst und keine Dateien und Kontakte zur Verteilung mehr hochgeladen werden, bis durch Löschen vorhandener Daten wieder Volumen verfügbar ist, oder durch Abschluss eines kostenpflichtigen Abonnements mehr Serverkapazität zur Verfügung steht." },
        { "text": "Nach Ablauf der kostenlosen Nutzung können keine weiteren Daten mehr erfasst und keine Dateien und Kontakte zur Verteilung mehr hochgeladen werden. Das Collada Portal kann in diesen Fall für eine Übergangszeit weiterhin genutzt werden, so dass durch den Kauf eines kostenpflichtigen Abonnements die Sperre ohne Datenverlust aufgehoben werden kann." },
        { "text": "Bei Nicht-Nutzung eines abgelaufenen Testkontos über einen Zeitraum von mehr als sechs Monaten wird dieses durch den Anbieter dauerhaft und endgültig gelöscht. Die erfassten Daten und hochgeladenen Dateien und Kontakte werden damit von den Servern des Anbieters gelöscht." }
      ]
    },
    {
      "title": "Kostenpflichtige Abonnements",
      "sections": [
        { "text": "Der Abschluss eines kostenpflichtigen Vertrags erfolgt im Collada-Portal auf welches der Kunde im Rahmen des bzw. auch nach Ablauf des kostenlosen Testzugangs Zugriff erhält. Zum Abschluss eines Abonnements ist die Angabe eines Account Holders notwendig, welcher als Kontakt und Ansprechpartner für die Kommunikation zwischen Anbieter und Kunde dient." },
        { "text": "Ein Abonnement wird jeweils über eine bestimmte Mindestlaufzeit von entweder einem Monat oder einem Jahr abgeschlossen." },
        { "text": "Die Kosten für das gewählte Abonnement sind für den gewählten Zeitraum im Voraus zu zahlen." },
        { "text": "Es können jederzeit während der Laufzeit eines Abonnements höherwertige Abonnements unter Anrechnung der bereits in Anspruch genommenen Zeitspanne abgeschlossen werden." },
        { "text": "Die Herabstufung auf geringere Abonnements (geringerer Leistungsumfang oder kürzere Laufzeit) ist mit Wirkung zur jeweils nächsten Abrechnungsperiode möglich." },
        { "text": "Die Laufzeit der Abonnements verlängert sich automatisch gemäß des gebuchten Abonnements falls nicht rechtzeitig fünf Werktage vor Ablauf des aktuellen Abrechnungszeitraums gekündigt wurde." },
        { "text": "Bei Ausbleiben von Zahlungen und damit einhergehendem Verzug ist der Anbieter berechtigt, das Nutzungskonto des Kunden unverzüglich zu sperren." }
      ]
    },
    {
      "title": "Rechte des Kunden bei Mängeln",
      "sections": [
        { "text": "Mängel, die der Kunde im Rahmen der kostenlosen Nutzung an den Anbieter meldet, müssen vom Anbieter nicht verpflichtend behoben werden. " },
        { "text": "Mängel betreffend des Colladas Portal oder der Collada-App, die der Kunde im Zusammenhang mit seiner kostenpflichtigen Nutzung im Rahmen von Abonnements gem. § 6 an den Anbieter meldet, werden seitens des Anbieters analysiert und ggf. behoben." },
        { "text": "Ein Mangel ist gegeben, wenn eine wesentliche, zugesicherte oder im Rahmen der Testphase verfügbare und vom Kunden geprüfte Funktionalität gestört und diese daher durch den Kunden nicht nutzbar ist. Die Nichtverfügbarkeit einer nicht zugesicherten und auch im Rahmen der Testnutzung nicht verfügbaren Funktionalität stellt hingegen keinen Mangel dar. Die Prüfung, ob die für die geplanten Einsatzzwecke des Kunden notwendigen Funktionalitäten vorhanden sind, obliegt dem Kunden im Rahmen seiner kostenlosen Testnutzung." },
        { "text": "Eine vorzeitige Kündigung der zahlungspflichtigen Nutzung vor Ablauf der aktuellen Abrechnungsperiode ist gemäß § 543 Abs. 2 Satz 1 Nr. 1 BGB erst dann zulässig, wenn dem Anbieter ausreichend Möglichkeit zur Beseitigung der angemahnten Mängel eingeräumt wurde und die Mängelbeseitigung fehlgeschlagen ist. Ein Fehlschlagen der Mängelbeseitigung liegt dann vor, wenn diese unmöglich ist, diese vom Anbieter verweigert oder in nicht zumutbarer Weise verzögert wird, wenn begründete Zweifel an den Erfolgsaussichten bestehen oder wenn aus anderen Gründen eine Unzumutbarkeit für den Kunden gegeben ist." },
        { "text": "Nimmt der Kunde nach Meldung eines Mangels und vor Abschluss oder Fehlschlagen der Mängelbeseitigung durch den Anbieter eigenhändig oder durch einen Dritten Änderungen an der Collada Plattform oder der Collada App vor, welche über die bereitgestellten Anpassungs- und Individualisierungsmöglichkeiten der Lösung hinausgehen, erlöschen die Rechte des Anbieters welche aus dem Vorhandensein des gemeldeten Mangels resultieren. Die Rechte bleiben jedoch unberührt, sofern der Anbieter zu den Anpassungen im Rahmen der Ausübung des Selbstbeseitigungsrechts gem. § 536 a Abs. 2 BGB berechtigt ist und diese fachgerecht ausgeführt und nachvollziehbar dokumentiert wurden." }
      ]
    },
    {
      "title": "Haftungsbeschränkungen",
      "sections": [
        { "text": "Der Anbieter hat die Inhalte der Collada Plattform sorgfältig erstellt. Er übernimmt für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte der Collada-Plattform keine Gewähr."},
        { "text": "Durch Inanspruchnahme der kostenlosen Testphase kann der Kunde vor dem produktiven Einsatz prüfen, ob sich durch die Verwendung der individualisierten Formulare in Collada Risiken für das eigene Unternehmen oder Risiken für Dritte ergeben können. Daher obliegt die Verantwortung für die Abschätzung derartiger Risiken beim Kunden." },
        { "text": "Als Plattformanbieter ist die app affairs GmbH für die Zurverfügungstellung der Collada-Plattform verantwortlich und kann u.U. gemäß der folgenden Bedingungen haftbar sein, wenn die Collada Plattform bestehend aus Collada Portal und Collada App temporär nicht zur Verfügung steht und daraus ggf. Schäden beim Kunden resultieren. Für Schäden, die durch Nutzung der individuellen Formulare oder hochgeladenen Dateien entstehen, ist der Kunde selbst verantwortlich und eventuell auch gegenüber Dritten haftbar." },
        { "text": "Sieht sich der Kunde trotz der Möglichkeit, den Funktionsumfang und damit die Eignung der Collada Plattform für die eigenen Zwecke vorab zu prüfen, nicht in der Lage, seine Ziele unter Einsatz der Collada Plattform zu erreichen und entstehen hierdurch Kosten z.B. für die Entwicklung einer eigenen Lösung, ist der Anbieter nicht zur Leistung von Schadenersatz verpflichtet." },
        { "text": "Der Anbieter haftet im Rahmen der gesetzlichen Bestimmungen für Schäden",
          "subSections": [
            "aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung bzw. sonst auf vorsätzlichem oder fahrlässigem Verhalten des Anbieters oder dessen gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen;",
            "wegen des Fehlens oder des Wegfalls einer wesentlichen zugesicherten Eigenschaft bzw. bei Nichteinhaltung einer Garantie;",
            "die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung bzw. sonst auf vorsätzlichem oder grob fahrlässigem Verhalten des Anbieters oder dessen gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen."
        ] },
        { "text": "Der Anbieter haftet unter Begrenzung auf Ersatz des vertragstypischen, vorhersehbaren Schadens für solche Schäden, die auf einer leicht fahrlässigen Verletzung von wesentlichen Pflichten durch den Anbieter oder durch einen gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Pflichten sind Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde vertrauen darf (sog. Kardinalpflichten). Hingegen haftet der Anbieter nicht für mittelbare Schäden, insbesondere Mangelfolgeschäden, unvorhersehbare Schäden oder untypische Schäden sowie für entgangene Gewinne. Selbiges gilt für die Folgen von zufälligen Schäden oder höherer Gewalt. Die Haftung ist begrenzt auf das sechsfache der monatlichen Abonnementgebühren je Schadensfall." },
        { "text": "Die verschuldensunabhängige Haftung der app affairs GmbH nach § 536 a Abs. 1, 1. Alternative BGB wegen Mängeln, die bereits zum Zeitpunkt des Vertragsschlusses vorhanden sind, ist ausgeschlossen." },
        { "text": "Der Anbieter haftet bei einfach fahrlässig verursachtem Datenverlust nur für den Schaden, der auch bei ordnungsgemäßer und regelmäßiger, der Bedeutung der Daten angemessener Datensicherung durch den Kunden angefallen wäre; diese Begrenzung gilt nicht, wenn die Datensicherung aus vom Anbieter zu vertretenden Gründen behindert oder unmöglich war." },
        { "text": "Die vorstehenden Bestimmungen gelten sinngemäß auch für die Haftung des Anbieters im Hinblick auf den Ersatz vergeblicher Aufwendungen." },
        { "text": "Soweit rechtlich zulässig übernimmt der Anbieter für Schäden und Datenverlust bei kostenlosen Testaccounts keinerlei Haftung." },
        { "text": "Der Kunde hat die Pflicht, den Anbieter von Ansprüchen Dritter freizustellen, die im Zusammenhang mit Verstößen des Kunden gegen die Pflichten aus diesen Nutzungsbedingungen oder den für den Kunden geltenden Gesetzesvorschriften von Dritten geltend gemacht werden, sofern der Kunde die Verstöße zu vertreten hat."},
      ]
    },
    {
      "title": "Vertragslaufzeit, Beendigung des Vertragsverhältnisses",
      "sections": [
        { "text": "Das Vertragsverhältnis beginnt mit der Erstellung eines Nutzerkontos und hat bei den kostenpflichtigen Abonnements - je nach vom Kunden gewähltem Abonnement - eine Laufzeit von einem Monat oder einem Jahr. Es verlängert sich, sofern es nicht von einer der Parteien zum jeweiligen Ende der Laufzeit mit einer Frist von fünf Werktagen gekündigt wurde, automatisch um entweder einen Monat oder 1 Jahr, abhängig vom gebuchten Abonnement." },
        { "text": "Der Anbieter hat das Recht auf Kündigung aus wichtigem Grund. Ein solcher wichtiger Grund liegt insbesondere dann vor, wenn der Kunde gegen Gesetze, gegen Rechte Dritter oder gegen die Datenschutzbestimmungen des Anbieters verstößt und dem Anbieter unter Berücksichtigung der Gesamtumstände des Einzelfalls und unter Abwägung der beiderseiti-gen Interessen die Fortsetzung des Vertragsverhältnisses bis zum vereinbarten Kündigungstermin oder bis zum Ablauf der Kündigungsfrist nicht zugemutet werden kann." },
        { "text": "Dem Anbieter ist es vorbehalten, im Falle eines Verdachts einer missbräuchlichen Nutzung der Collada Plattform oder wesentlichen Vertragsverletzungen durch den Kunden, diesen Vorgängen nachzugehen, Vorkehrungen hiergegen zu veranlassen und wenn ein begründeter Verdacht vorliegt, den Account zu sperren. Für den Fall, dass der Verdacht ausgeräumt wird, wird der Account auf Veranlassung des Anbieters wieder freigeschaltet. Sollte der Verdacht nicht ausgeräumt werden können, steht dem Anbieter ein außerordentliches Kündigungsrecht zu." },
        { "text": "Die Kündigung des Abonnements durch den Kunden erfolgt durch Verwendung der entsprechenden Kündigungsfunktion im Collada Portal oder durch schriftliche Mitteilung an den Anbieter unter Angabe des Kunden- oder Mandantennamens bzw. des Account Holders." },
        { "text": "Nach Kündigung des Abonnements werden die eingerichteten individuellen Formulare sowie die erfassten bzw. hochgeladenen Daten gelöscht. Es obliegt jedem Vertragspartner regelmäßig Datensicherungen zu unternehmen." }
      ]
    },
    {
      "title": "Salvatorische Klausel",
      "sections": [
        { "text": "Sollte eine Bestimmung dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, wird hierdurch die Wirksamkeit der übrigen Bestimmungen der Nutzungsbedingungen nicht berührt. Anstelle der unwirksamen Bestimmung soll eine Bestimmung als vereinbart gelten, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommt. Die Wirksamkeit von Verträgen, die mit diesen Nutzungsbedingungen im Zusammenhang stehen, bleibt unberührt." }
      ]
    },
    {
      "title": "Sonstige Vereinbarungen",
      "sections": [
        { "text": "Diese Nutzungsbedingungen unterliegen dem deutschen Recht unter Ausschluss des UN-Kaufrechts. Ausschließlicher Gerichtsstand ist München." }
      ]
    }

  ]
};
