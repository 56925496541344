/* eslint-disable no-console */
const crypto = require('crypto');
const AesUtil = require('./aesBuffer');

let processedConnectionInfo = null;

const connectionInfoKey = Buffer.from('EM047zHrP4XVp/DzdTLouu6jZxWNfb7rP8kn7WuXm/E=', 'base64');

const getConnectionInfo = (ciStr = null) => {
  let strToParse = null;
  if (ciStr == null && processedConnectionInfo != null) {
    return processedConnectionInfo;
  }
  if (ciStr == null) {
    const storedConnectionInfo = localStorage.getItem('col_ci');
    if (storedConnectionInfo == null) {
      // something missing!
      throw new Error('Encryption info missing!');
    }
    strToParse = storedConnectionInfo;
  } else {
    strToParse = ciStr;
  }
  processedConnectionInfo = JSON.parse(AesUtil.decrypt(strToParse, connectionInfoKey));
  if (processedConnectionInfo.masterKey) {
    processedConnectionInfo.masterKey = Buffer.from(processedConnectionInfo.masterKey, 'base64');
  }
  return processedConnectionInfo;
};

const fetchCryptoInfo = () => {
  const ci = getConnectionInfo();
  if (ci != null) {
    if (typeof ci.masterKey === 'string' || ci.masterKey instanceof String) {
      return { masterKey: Buffer.from(ci.masterKey, 'base64') };
    }
    return { masterKey: ci.masterKey };
  }
  return null;
};

module.exports = {
  connectionInfoKey,
  fetchCryptoInfo,
  encrypt: (plainText, key = null) => {
    let decKey = null;
    if (key == null) {
      const { masterKey } = fetchCryptoInfo();
      decKey = masterKey;
    } else {
      decKey = key;
    }
    return AesUtil.encrypt(Buffer.from(plainText), decKey);
  },
  decrypt: (plainText, key = null) => {
    let decKey = null;
    if (key == null) {
      const { masterKey } = fetchCryptoInfo();
      decKey = masterKey;
    } else {
      decKey = key;
    }
    return AesUtil.decrypt(plainText, decKey).toString('utf8');
  },
  decryptToBuffer: (plainText, key = null) => {
    let decKey = null;
    if (key == null) {
      const { masterKey } = fetchCryptoInfo();
      decKey = masterKey;
    } else {
      decKey = key;
    }
    return AesUtil.decrypt(plainText, decKey);
  },
  getConnectionInfo,
  clearProcessedConnectionInfo: () => {
    processedConnectionInfo = null;
  },
  createMasterKey: () => {
    const masterKeyBase = crypto.randomBytes(32).toString('base64');
    const salt = crypto.randomBytes(64);
    const iterations = parseInt(Math.random() * 100 + 100, 10);
    const masterKey = crypto.pbkdf2Sync(masterKeyBase, salt, iterations, 32, 'sha512');
    const masterKeyStr = Buffer.from(masterKey).toString('base64');
    return masterKeyStr;
  },
  createKeyFromSeed: (seed) => {
    console.log('createKeyfromSeed 0', seed);
    let completeSeed = seed;
    while (completeSeed.length < 32) {
      completeSeed = `${completeSeed}${seed}`;
    }
    completeSeed = completeSeed.substring(0, 32);
    const keyBase = Buffer.from(completeSeed, 'utf-8');
    const salt = Buffer.from('qykAgSFC3WbEA+RkR0GenXeBr8JgTbRlf78vBxSVm0LqvNqXtXqIvPLkr1+Y2kpg');
    const iterations = 65;
    const key = crypto.pbkdf2Sync(keyBase, salt, iterations, 32, 'sha512');
    const masterKeyStr = Buffer.from(key).toString('base64');
    return masterKeyStr;
  },

};
