<template>
  <CustomModal v-model="showModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{title}}</template>
    <div>
      <span v-html="text"></span><br/>
      <input @keyup.enter="confirm" ref="inputField" data-cy="prompt-input" type="text" v-model="newValue"/>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';

export default {
  name: 'PromptModal',
  props: ['title', 'text'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showModal: false,
      resolve: null,
      reject: null,
      promise: null,
      currentValue: null,
      newValue: null,
    };
  },
  methods: {
    autoFocus() {
      if (this.$refs.inputField == null) {
        setTimeout(this.autoFocus, 200);
      } else {
        // console.log('set focus!');
        this.$refs.inputField.focus();
      }
    },
    async show(currentValue) {
      this.currentValue = currentValue;
      this.newValue = currentValue;
      this.showModal = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      setTimeout(this.autoFocus, 200);
      return this.promise;
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      if (this.newValue == null || this.newValue.trim() === '') {
        return;
      }
      this.resolve(this.newValue);
      this.hide();
    },
    cancel() {
      this.resolve(null);
      this.hide();
    },
  },
};
</script>

<style scoped>

::v-deep(.modal_content input) {
  width: calc(100% - 20px);
  display: inline-block;
  background-color: white;
  border: 1px solid lightgray;
  position: relative;
  padding: 8px;
  margin: 12px 0px;
  font-size: 16px;
}
</style>
